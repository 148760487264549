import React, { useEffect, useState } from 'react';

import Auth from '@aws-amplify/auth';
import { Navigate, useLocation } from 'react-router-dom';

import type { AmplifyUser } from '../../../@types/types';

interface PrivateRouteConfig {
  adminOnly?: boolean;
}

const withPrivateRoute =
  ({ adminOnly }: PrivateRouteConfig) =>
  (Component: React.FC) => {
    const PrivateRoute = (props: any) => {
      const [isLoggedIn, setIsLoggedIn] = useState<null | AmplifyUser>(null);
      const location = useLocation();

      useEffect(() => {
        (async () => {
          const currentUserInfo = await Auth.currentUserInfo();
          setIsLoggedIn(currentUserInfo ?? false);
        })();
      }, []);

      if (isLoggedIn === null) {
        return null;
      }

      if (adminOnly && isLoggedIn && isLoggedIn.attributes?.['custom:role'] !== 'admin') {
        return null;
      }

      return isLoggedIn ? <Component {...props} /> : <Navigate to={'/login'} state={{ from: location }} />;
    };
    return PrivateRoute;
  };

export default withPrivateRoute;
