// @ts-ignore
import { mergeAttributes } from '@tiptap/core';
import MentionExtension from '@tiptap/extension-mention';
import moment from 'moment';

import type { GetFormStateFn, GetPreviewStateFn } from '../hooks/use-editor';

export const dateFormatMoment = 'Do MMMM YYYY';

const formatAddressIngredient = (value?: string | number): string => {
  if (value === undefined) {
    return '';
  }

  return `${value}, `;
};

interface FormatAddressProps {
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  region: string;
  postCode: string;
}

const formatAddress = ({ addressLine1, addressLine2, city, country, region, postCode }: FormatAddressProps): string =>
  `${formatAddressIngredient(addressLine1)} ${formatAddressIngredient(addressLine2)} ${formatAddressIngredient(
    region
  )} ${formatAddressIngredient(postCode)} ${formatAddressIngredient(city)} ${country || ''}`;

const formatFormValue = (key: string, value: any): string => {
  if (!value) {
    return '';
  }

  if (key === 'address' && typeof value === 'object') {
    const res = formatAddress(value);
    return res;
  }

  if (typeof value === 'string' && ['inception_date', 'expiry_date'].includes(key)) {
    return moment.utc(value).format(dateFormatMoment);
  }

  return value;
};

interface MentionProps {
  getFormState: GetFormStateFn;
  getPreviewState: GetPreviewStateFn;
}

export const Mention = ({ getFormState, getPreviewState }: MentionProps) => {
  const renderTransformedMention = (id: string, currentValue?: string): string => {
    const formValues = getFormState();
    const value = formatFormValue(id, formValues[id] || currentValue);

    return value.toString();
  };

  return MentionExtension.extend({
    renderHTML({ HTMLAttributes, node }: any) {
      const isPreviewMode = getPreviewState();
      const { id } = node.attrs;

      const formattedValue = isPreviewMode
        ? renderTransformedMention(id)
        : this.options.renderLabel({
            options: this.options,
            node,
          });

      if (!formattedValue) {
        return ['span'];
      }

      return [
        'span',
        mergeAttributes({ 'data-type': 'mention', title: id }, this.options.HTMLAttributes, HTMLAttributes),
        formattedValue,
      ];
    },
  });
};
