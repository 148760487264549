import ListItemExtension from '@tiptap/extension-list-item';

const getIndent = (element: HTMLElement): number => {
  if (element.tagName === 'BODY') return 0;
  const tagName = element.tagName;
  const result = tagName === 'LI' ? 1 : 0;
  const parent = element.parentElement;
  return parent ? result + getIndent(parent) : result;
};

const isDecimalNumbering = (element: HTMLElement): boolean => {
  if (element.tagName === 'BODY') return false;
  const tagName = element.tagName;
  if (tagName === 'OL') {
    if (element.getAttribute('type') === '1.1') {
      return true;
    }
  }
  const parent = element.parentElement;
  return parent ? isDecimalNumbering(parent) : false;
};

export const ListItem = ListItemExtension.extend({
  // @ts-ignore: Unreachable code error - somehow ts does not like this
  addKeyboardShortcuts() {
    const parent = this.parent ? this.parent() : {};
    return {
      ...parent,
      Tab: () => {
        return false;
      },
      'Shift-Tab': () => {
        return false;
      },
    };
  },
  addAttributes() {
    const parent = this.parent ? this.parent() : {};
    return {
      ...parent,
      style: {
        parseHTML: (element: any) => {
          if (isDecimalNumbering(element)) {
            const parent = element.parentElement;
            const indent = parent ? getIndent(parent) : 0;
            return `padding-left: ${30 + indent * 8}px !important`;
          }
          return '';
        },
      },
    };
  },
});
