import React from 'react';

import type { UIOnChangeFn } from '../../../@types/types';
import { getValueFromObjOrSimple } from '../../helpers/getValue';
import InputCheckbox from '../InputCheckbox';
import type { InputFieldProps } from '../InputField';
import InputField from '../InputField';

type Option =
  | {
      labelText: string;
      value: string;
    }
  | string;

type Value = string | string[];

interface InputCheckboxGroupProps extends InputFieldProps {
  isReadOnly: boolean;
  onChange: UIOnChangeFn<Value>;
  value: Value;
  item: { items: { enum: Option[] } };
}

const InputCheckboxGroup: React.FC<InputCheckboxGroupProps> = ({
  className,
  descriptionText,
  errors,
  id,
  isRequired,
  isReadOnly,
  labelText,
  name = 'checkbox-group',
  onChange,
  value,
  item,
}) => {
  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
    >
      {item?.items?.enum?.map((option) => (
        <InputCheckbox
          className="my-6"
          errors={errors}
          id={[name, option].join('.')}
          isChecked={value.includes(getValueFromObjOrSimple(option, 'value'))}
          isRequired={isRequired}
          key={getValueFromObjOrSimple(option, 'value')}
          labelText={getValueFromObjOrSimple(option, 'labelText')}
          name={name}
          onChange={(e) => {
            const defaultValue = Array.isArray(value) ? value : [];
            const nextValue = defaultValue.includes(e.target.value)
              ? defaultValue.filter((i) => i !== e.target.value)
              : [...defaultValue, e.target.value];

            return onChange(nextValue, name);
          }}
          value={getValueFromObjOrSimple(option, 'value')}
          isDisabled={isReadOnly}
          readOnly={isReadOnly}
        />
      ))}
    </InputField>
  );
};

export default InputCheckboxGroup;
