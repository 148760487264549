import { useEffect } from 'react';

import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';

import type { S3Schema, UIFormValues } from '../../@types/types';

type SetFormValuesFn = (values: UIFormValues) => void;
type GetFormStateFn = { (): UIFormValues; (): any };

export const useEocBinderEffects = (
  _schema: S3Schema,
  formValues: UIFormValues,
  setFormValues: SetFormValuesFn,
  getFormState: GetFormStateFn
): void => {
  useEffect(() => {
    if (!('premium_settlement_days' in formValues)) {
      const nextFormValues = cloneDeep(getFormState());
      set(nextFormValues, 'premium_settlement_days', 30);
      setFormValues(nextFormValues);
    }
  }, []);

  useEffect(() => {
    if (formValues.office !== 'Ed Broking Bermuda') {
      const nextFormValues = cloneDeep(getFormState());
      set(nextFormValues, 'direct_placement', 'No');
      setFormValues(nextFormValues);
    }
  }, [formValues.office]);

  useEffect(() => {
    if (formValues.lloyds_binder === 'No') {
      const nextFormValues = cloneDeep(getFormState());
      set(nextFormValues, 'lineage', 'No');
      setFormValues(nextFormValues);
    }
  }, [formValues.lloyds_binder]);
};
