import React from 'react';

import type { Editor as TipTapEditor } from '@tiptap/react';
import { EditorContent } from '@tiptap/react';
import clsx from 'clsx';

import Styling from './Styling';

interface EditorProps {
  editor: TipTapEditor | null;
  isDisabled: boolean;
  scrollPosition?: number;
  setScrollPosition?: (position: number) => void;
}

export const Editor: React.FC<EditorProps> = ({
  editor,
  isDisabled,
  setScrollPosition: _setScrollPosition,
  scrollPosition: _scrollPosition,
}) => {
  return (
    <>
      <Styling />
      <div className={clsx('editor-content', isDisabled && 'opacity-50 cursor-not-allowed')}>
        <EditorContent editor={editor} />
      </div>
    </>
  );
};
