// @ts-ignore
import { Mark, mergeAttributes } from '@tiptap/core';

export interface UppercaseMarkOptions {
  HTMLAttributes: Record<string, any>;
}

export const UppercaseMark = Mark.create<UppercaseMarkOptions>({
  name: 'uppercase',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [
      {
        style: 'text-transform',
        getAttrs: (value: string) => /^(uppercase)$/.test(value as string) && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }: any) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, {
        ...HTMLAttributes,
        style: 'text-transform: uppercase;',
      }),
      0,
    ];
  },

  // @ts-ignore: Unreachable code error - somehow ts does not like this
  addCommands() {
    return {
      toggleUppercase:
        () =>
        ({ commands }: any) => {
          return commands.toggleMark('uppercase');
        },
    };
  },
});
