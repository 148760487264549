import React from 'react';

import clsx from 'clsx';

const H4: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
  <h4 className={clsx('text-lg leading-relaxed font-semibold', className)} {...props}>
    {children}
  </h4>
);

export default H4;
