import React from 'react';

import clsx from 'clsx';

import type { UIComponentSize } from '../../@types/types';
import Icon from './Icon';
import type { SvgIconName } from './Svg';

type ButtonKindColor = 'green' | 'primary' | 'secondary' | 'tertiary' | 'danger-secondary' | 'danger' | 'ghost';
type ButtonVariants = Record<ButtonKindColor, string>;

const variants: ButtonVariants = {
  green: 'text-white bg-green-600 hover:bg-green-800 active:bg-green-1000 disabled:bg-green-300',
  primary: 'text-white bg-blue-600 hover:bg-blue-800 active:bg-blue-1000 disabled:bg-blue-300',
  secondary:
    'active:bg-gray-200 bg-white border border-gray-500 disabled:text-gray-600 shadow-sm hover:bg-gray-100 text-gray-900',
  tertiary: 'active:bg-gray-200 bg-white border border-gray-500 disabled:text-gray-600 hover:bg-gray-100 text-blue-800',
  'danger-secondary':
    'active:bg-gray-200 bg-white border border-gray-500 disabled:text-gray-600 hover:bg-gray-100 text-red-800',
  danger: 'text-white bg-red-800 hover:bg-red-900 active:bg-red-1000 disabled:bg-red-500',
  ghost: 'text-gray-700 rounded-lg bg-white border border-gray-300 font-normal disabled:bg-white/30 hover:bg-gray-100',
};

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  as?: any; //TODO: find better type for passing html elements
  iconName?: SvgIconName;
  isDisabled?: boolean;
  kind?: ButtonKindColor;
  size?: UIComponentSize;
  type?: string;
}

const Button: React.FC<ButtonProps> = ({
  as: Component = 'button',
  children,
  className,
  iconName,
  isDisabled,
  kind = 'secondary',
  type = 'button',
  // size = 'normal',
  ...props
}) => (
  <Component
    className={clsx(
      'inline-flex items-center justify-center relative transition duration-75 ease-in font-medium text-sm rounded disabled:cursor-not-allowed focus:outline-none focus:shadow-outline',
      !clsx(className).match(/(p[xy]?)-(\d+)/) && 'px-4',
      !clsx(className).match(/(h)-(\d+)/) && 'h-12',
      variants[kind],
      className
    )}
    disabled={isDisabled}
    type={type}
    {...props}
  >
    {iconName && <Icon name={iconName} className={clsx('w-4 mr-2 ')} />}
    {children}
  </Component>
);

export default Button;
