import formatDateFns from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isFinite from 'lodash.isfinite';

import type { Address } from '../../@types/types';

type CurrencyFormatterArgs = Intl.NumberFormatOptions & {
  amount?: number;
  currency?: string;
};

export const currencyFormatter = (args: CurrencyFormatterArgs = {}): string | false => {
  const { amount, currency = 'USD', ...options } = args;

  if (!amount && amount !== 0) {
    return false;
  }

  return Number(amount).toLocaleString('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
  });
};

export const numberFormatter = (value: number | string): number | string => {
  const parsedAmount = Number.parseFloat(value.toString());

  if (!isFinite(parsedAmount)) {
    return value;
  }

  return Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    style: 'currency',
    currency: 'USD',
  }).format(parsedAmount);
};

export const addressFormatter =
  (separator: string) =>
  (values: string[]): string =>
    values.filter(Boolean).join(separator);

export const formatNumber = (num: number, dec = 0): string => (isNaN(num) ? '' : num.toFixed(dec));

export const dateFormatter = (date?: Date | string): string | false => {
  if (!date) {
    return false;
  }

  return formatDateFns(typeof date === 'string' ? parseISO(date) : date, 'dd/MMM/yyyy');
};

export const dateTimeFormatter = (date?: Date | string): string | false => {
  if (!date) {
    return false;
  }

  return formatDateFns(typeof date === 'string' ? parseISO(date) : date, 'dd/MMM/yyyy HH:mm');
};

export const fullNameFormatter = (values: string[] | { firstName?: string; lastName?: string }): string => {
  const [firstName, lastName] = Array.isArray(values) ? values : [values?.firstName, values?.lastName];

  return `${firstName} ${lastName}`;
};

export const fullAddress = (values?: Address) => {
  const { address_line_1, address_line_2, state, zipcode } = values || {};
  return `${address_line_1 || ''} ${address_line_2 || ''} ${state || ''} ${zipcode || ''}`;
};
