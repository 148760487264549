import axios from 'axios';

import type { ServerResponse, Template } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const fetchTemplate = async (id: string, authToken: string): Promise<ServerResponse<Template>> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/templates-spa-${auth.authProvider}/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
