import React from 'react';

import type { NumberFormatValues } from 'react-number-format';
import NumberFormat from 'react-number-format';

import Input from './Input';
import type { InputFieldProps } from './InputField';
import InputField from './InputField';
import InputReadOnly from './InputReadOnly';

type Value = { value: number | string; unit: string; amount?: number };

interface InputTemperatureProps extends InputFieldProps {
  defaultValue: Value;
  isReadOnly: boolean;
  onChange?: (value: string | Value, fieldName?: string) => void;
  value: Value;
}

const InputTemperature: React.FC<InputTemperatureProps> = ({
  className,
  defaultValue,
  descriptionText,
  errors,
  id,
  isReadOnly,
  isRequired,
  labelText,
  name,
  noErrorLabel,
  onChange,
  value = {},
}) => {
  // TODO: °F
  const unit = '°C';
  const val = value?.value;

  const handleChange = ({ floatValue }: NumberFormatValues) => {
    if (onChange) {
      onChange(floatValue || floatValue === 0 ? { value: floatValue, unit } : '', name);
    }
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
      noErrorLabel={noErrorLabel}
    >
      {!isReadOnly && (
        <div className="relative">
          <div className="absolute inset-y-0 right-0 flex items-center justify-center w-8">°C</div>

          <NumberFormat
            allowNegative
            aria-describedby={descriptionText ? `${name}Description` : undefined}
            className="aui-input aui-input-temp"
            customInput={Input}
            decimalScale={0}
            defaultValue={defaultValue && defaultValue.value}
            errors={errors}
            fixedDecimalScale
            id={name}
            isNumericString
            maxLength={22}
            name={name}
            onValueChange={handleChange}
            thousandSeparator
            value={val}
            isRequired={isRequired}
          />
        </div>
      )}

      {isReadOnly && (
        <InputReadOnly
          key={JSON.stringify(value)}
          id={id || name}
          name={name}
          errors={errors}
          value={value.value || value.value === 0 ? value.value : undefined}
        />
      )}
    </InputField>
  );
};

export default InputTemperature;
