import React from 'react';

import { Switch as UISwitch } from '@headlessui/react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { classNames } from '../../../../utils';

interface SwitchProps {
  className?: string;
  isChecked: boolean;
  onClick: (checked: boolean) => void;
  isDisabled: boolean;
}

export const Switch: React.FC<SwitchProps> = ({ className, isChecked, onClick, isDisabled }) => {
  return (
    <UISwitch.Group as="div" className={clsx('flex items-center', className)}>
      <UISwitch
        disabled={isDisabled}
        checked={isChecked}
        onChange={onClick}
        className={classNames(
          isChecked ? 'bg-indigo-600' : 'bg-gray-200',
          'disabled:cursor-not-allowed relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            isChecked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </UISwitch>
      <UISwitch.Label as="span" className="ml-3">
        <span className="text-sm font-medium cursor-pointer text-gray-900">Preview</span>
      </UISwitch.Label>
    </UISwitch.Group>
  );
};

Switch.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
