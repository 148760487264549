import React, { useState } from 'react';

import { XCircleIcon } from '@heroicons/react/solid';
import moment from 'moment';
import qs from 'qs';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Pagination from '../components/Pagination';
import withPrivateRoute from '../containers/Auth/PrivateRoute';
import { useCurrentUser } from '../hooks/useCurrentUser';
import { fetchTemplates } from '../queries';

const pageSize = 15;

function escape(str: string): string {
  return str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
}

const Templates: React.FC = () => {
  const navigate = useNavigate();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { user } = useCurrentUser();

  const [page, setPage] = useState(0);

  const [searchUmr, setSearchUmr] = useState('');

  const { data: inputData, status } = useQuery('templates', () => fetchTemplates(queryParams.authToken as string), {
    refetchOnWindowFocus: false,
    enabled: user?.attributes?.['custom:role'] === 'admin',
  });

  inputData?.sort((a, b) => {
    return (a.risk_details?.umr.trim() ?? '') > (b.risk_details?.umr.trim() ?? '') ? 1 : -1;
  });
  const data = inputData?.filter((a) => a.isGeneric);

  if (status === 'loading') {
    return (
      <div className=" loader flex justify-center items-center">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900" />
      </div>
    );
  }

  return (
    <section aria-labelledby="templates_card">
      <div className="bg-white pt-4 shadow sm:rounded-md sm:overflow-hidden">
        <div className="px-4 flex justify-between sm:px-6">
          <h2 id="templates_card" className="text-lg leading-6 font-medium text-gray-900">
            Templates
          </h2>
          <div className="flex">
            <div className="relative rounded-md shadow-sm">
              <div className="flex">
                <input
                  name="umr"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md px-4 py-2 pr-10 search-input"
                  placeholder="UMR/Assigned to"
                  autoComplete="off"
                  value={searchUmr}
                  onChange={(event) => {
                    setSearchUmr(event.target.value);
                  }}
                />
                {searchUmr && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                    <XCircleIcon
                      className="h-5 w-5 text-gray-300 cursor-pointer"
                      aria-hidden="true"
                      onClick={() => setSearchUmr('')}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="my-6 px-4 sm:px-6 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        UMR
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Assigned to
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Created at
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Updated at
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Version
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only ">Show</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data ?? [])
                      .filter(
                        (t) =>
                          !searchUmr ||
                          new RegExp(`${escape(searchUmr)}`, 'i').test(t.risk_details?.umr || '') ||
                          new RegExp(`${escape(searchUmr)}`, 'i').test(t.user?.email || '')
                      )
                      .slice(page * pageSize, (page + 1) * pageSize)
                      .map((template) => (
                        <tr key={template.sk} className="bg-white divide-y divide-gray-200">
                          <td
                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            style={{ borderBottom: '1px solid rgb(229, 231, 235)' }}
                          >
                            {template.risk_details?.umr}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {template.user ? template.user.email : ''}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {template.created_at && moment(template.created_at).format('DD/MM/YYYY HH:mm')}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {(template.updated_at ?? template.created_at) &&
                              moment(template.updated_at ?? template.created_at).format('DD/MM/YYYY HH:mm')}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{template.version}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            <a
                              onClick={() => navigate(`/templates/${template.id}`)}
                              className="text-indigo-600 cursor-pointer hover:text-indigo-900"
                            >
                              Show
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <Pagination
                  totalResults={
                    (data || []).filter(
                      (t) =>
                        !searchUmr ||
                        new RegExp(`${escape(searchUmr)}`, 'i').test(t.risk_details?.umr || '') ||
                        new RegExp(`${escape(searchUmr)}`, 'i').test(t.user?.email || '')
                    ).length
                  }
                  pageSize={pageSize}
                  onPageChange={setPage}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const TemplatesPage = withPrivateRoute({})(Templates);
