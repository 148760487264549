import React from 'react';

import type { InputPercentageProps } from '../InputPercentage';
import InputPercentage from '../InputPercentage';

interface InputPercentageSchemaProps extends InputPercentageProps {
  item: any;
}

const InputPercentageSchema: React.FC<InputPercentageSchemaProps> = ({ item, ...props }) => (
  <InputPercentage
    {...props}
    isAllowed={({ floatValue = 0, value }) => {
      if (value === '' || (!item?.maximum && !item?.minimum)) {
        return true;
      }

      if (item?.maximum) {
        return floatValue <= item?.maximum;
      }

      if (item?.minimum) {
        return floatValue >= item?.minimum;
      }

      return false;
    }}
  />
);

export default InputPercentageSchema;
