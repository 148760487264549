import React from 'react';

import type { S3SchemaProperties } from '../../../@types/types';
import type { InputTextProps } from '../InputText';
import InputText from '../InputText';

interface InputNumberSchemaProps extends InputTextProps {
  item: S3SchemaProperties;
}

const InputNumberSchema: React.FC<InputNumberSchemaProps> = ({ item: schema, ...props }) => {
  return <InputText type={schema.type} min={schema.minimum} max={schema.maximum} {...props} />;
};

export default InputNumberSchema;
