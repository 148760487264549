import { useState } from 'react';

import type { Editor } from '@tiptap/react';
declare type Level = 1 | 2 | 3 | 4 | 5 | 6;

interface FormatType {
  bold: boolean;
  italic: boolean;
  underline: boolean;
  strike: boolean;
  uppercase: boolean;
  heading: boolean;
  level: Level;
  orderedList: boolean;
  bulletList: boolean;
}

export function useFormatPainter(editor: Editor | any) {
  const [format, setFormat] = useState<FormatType>({
    bold: false,
    italic: false,
    underline: false,
    strike: false,
    uppercase: false,
    heading: false,
    level: 1,
    orderedList: false,
    bulletList: false,
  });

  const [applyEnabled, setApplyEnabled] = useState(false);

  function updateFormat() {
    setFormat({
      bold: editor.isActive('bold'),
      italic: editor.isActive('italic'),
      underline: editor.isActive('underline'),
      strike: editor.isActive('strike'),
      uppercase: editor.isActive('uppercase'),
      heading: editor.isActive('heading'),
      level: editor.isActive('heading') ? editor.getAttributes('heading').level : 1,
      orderedList: editor.isActive('orderedList'),
      bulletList: editor.isActive('bulletList'),
    });
    setApplyEnabled(true);
  }

  function applyFormat() {
    if (!applyEnabled) return;
    const editorChain = editor.chain().focus();
    if (format.orderedList !== editor.isActive('orderedList')) {
      editorChain.toggleOrderedList();
    }
    if (format.bulletList !== editor.isActive('bulletList')) {
      editorChain.toggleBulletList();
    }
    if (format.heading) {
      editorChain.unsetAllMarks().setHeading({ level: format.level });
    } else {
      if (editor.isActive('heading')) {
        editorChain.toggleHeading({ level: editor.getAttributes('heading').level });
      }
      if (format.bold) {
        editorChain.setBold();
      } else {
        editorChain.unsetBold();
      }
      if (format.italic) {
        editorChain.setItalic();
      } else {
        editorChain.unsetItalic();
      }
      if (format.underline) {
        editorChain.setUnderline();
      } else {
        editorChain.unsetUnderline();
      }
      if (format.strike) {
        editorChain.setStrike();
      } else {
        editorChain.unsetStrike();
      }
    }

    editorChain.run();
  }

  return {
    updateFormat,
    applyFormat,
    applyEnabled,
  };
}
