import React from 'react';

import type { S3SchemaProperties } from '../../../@types/types';
import type { InputTextProps } from '../InputText';
import InputText from '../InputText';

type SchemaType = undefined | 'text' | 'number' | 'object' | 'array';

interface InputTextSchemaProps extends InputTextProps {
  item: S3SchemaProperties;
}

const getInputType = (schema: S3SchemaProperties): SchemaType => {
  if ((schema.type === 'string' || schema.type === 'number') && 'enum' in schema) {
    return undefined;
  }

  if (schema.type === 'string') {
    return 'text';
  }

  return schema.type;
};

const InputTextSchema: React.FC<InputTextSchemaProps> = ({ item: schema, ...props }) => (
  <InputText type={getInputType(schema)} min={schema.minimum} max={schema.maximum} {...props} />
);

export default InputTextSchema;
