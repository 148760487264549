import React, { Component } from 'react';

import { Auth } from '@aws-amplify/auth';
import { Navigate } from 'react-router-dom';

class Logout extends Component {
  componentDidMount() {
    setTimeout(() => {
      Auth.signOut();
    }, 1000);
  }

  render() {
    return <Navigate to={'/login'} />;
  }
}

export default Logout;
