export const isSimpleType = (value?: any): boolean => value && ['string', 'number', 'boolean'].includes(value);

export const getValueFromObjOrSimple = <T = string>(objOrString: any, key: string): T =>
  objOrString[key] ? objOrString[key] : objOrString;

export const getValueOrUndefined = <T = string>(objOrString: any, key: string): T | undefined =>
  objOrString[key] ? objOrString[key] : undefined;

export const getBooleanOrFalse = (objOrString: any, keyToBool: string): boolean =>
  isSimpleType(objOrString) ? false : objOrString[keyToBool];
