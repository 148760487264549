import { useRef, useEffect } from 'react';

import Bold from '@tiptap/extension-bold';
import HardBreak from '@tiptap/extension-hard-break';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import TableHeader from '@tiptap/extension-table-header';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import type { Editor } from '@tiptap/react';
import { useEditor as tiptapUseEditorHook, ReactRenderer } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { debounce } from 'lodash';
import { Fragment, Slice } from 'prosemirror-model';
import tippy from 'tippy.js';

import type { UIFormValues, UIInputValue } from '../../../../../../@types/types';
import { MentionList } from '../../MentionList';
import {
  Mention,
  OrderedList,
  ListItem,
  Indent,
  UppercaseMark,
  Subscript,
  Superscript,
  Table,
  TableRow,
  TableCell,
} from '../extensions';

export type GetFormStateFn = () => UIFormValues;
export type GetPreviewStateFn = () => boolean;

interface UseEditorHookProps {
  documentType: string;
  name: string;
  suggestionFields: string[];
  editorContent?: { [name: string]: UIInputValue };
  editable?: boolean;
  unformattedPaste: boolean;
  onUpdate: (content: string) => void;
  getFormState: GetFormStateFn;
  getPreviewState: GetPreviewStateFn;
}

export const useEditor = ({
  documentType,
  getFormState,
  getPreviewState,
  name,
  suggestionFields,
  editorContent = {},
  onUpdate,
  editable,
  unformattedPaste,
}: UseEditorHookProps): Editor | null => {
  const userContent: string = editorContent[name] || '';
  const unformattedPasteRef = useRef(unformattedPaste);
  useEffect(() => {
    unformattedPasteRef.current = unformattedPaste;
  }, [unformattedPaste]);

  const handleEditorUpdate = debounce(async (content) => {
    await onUpdate(content);
  }, 200);

  const editor = tiptapUseEditorHook({
    onUpdate({ editor }: any) {
      handleEditorUpdate(editor.getHTML());
    },
    editorProps: {
      attributes: {
        class: `wysiwyg ${documentType.toLowerCase()} prose pb-10 prose-sm sm:prose lg:prose-lg m-5 focus:outline-none`,
      },
      handlePaste(view: any, event: any, _slice: unknown) {
        if (unformattedPasteRef.current) {
          const text = event?.clipboardData?.getData('text/plain');
          const textFragment = Fragment.from(view.state.schema.text(text));
          const textSlice = new Slice(textFragment, 0, 0);
          //https://github.com/ProseMirror/prosemirror-view/blob/75c930281c4c45c214df750dd367c17efc315b8f/src/input.js#L542
          const singleNode =
            textSlice.openStart == 0 && textSlice.openEnd == 0 && textSlice.content.childCount == 1
              ? textSlice.content.firstChild
              : null;
          //https://github.com/ProseMirror/prosemirror-view/blob/75c930281c4c45c214df750dd367c17efc315b8f/src/input.js#L566
          const tr = singleNode
            ? view.state.tr.replaceSelectionWith(singleNode, (view as any).shiftKey)
            : view.state.tr.replaceSelection(textSlice);
          view.dispatch(tr.scrollIntoView().setMeta('paste', true).setMeta('uiEvent', 'paste'));
          return true;
        } else return false;
      },
    },
    extensions: [
      StarterKit.configure({
        orderedList: false,
        listItem: false,
        hardBreak: false,
      }),
      // Document,
      // Paragraph,
      // Text,
      Bold.extend({
        parseHTML() {
          return [
            ...(this.parent?.() ?? []),
            {
              tag: 'p',
              getAttrs: (node: Node) => (node as any)?.className === 'MsoBodyText2' && null,
            },
            {
              style: 'font-family',
              getAttrs: (style: string) => (typeof style === 'string' && style.includes('Bold') ? {} : false),
            },
          ];
        },
      }),
      Underline,
      Table(),
      TableRow(),
      TableHeader,
      OrderedList,
      ListItem,
      Indent,
      UppercaseMark,
      HardBreak.extend({
        addKeyboardShortcuts() {
          return {
            'Shift-Enter': () => this.editor.commands.setHardBreak(),
          };
        },
      }).configure({
        keepMarks: false,
      }),
      // HorizontalRule,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      // HorizontalRule,
      HorizontalRule.configure({
        HTMLAttributes: {
          class: 'horizontal-rule',
        },
      }),
      TextStyle,
      TableCell(),
      Mention({ getFormState, getPreviewState }).configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion: {
          char: '{{',
          items: ({ query }: any) => {
            return suggestionFields
              .filter((item) =>
                String(item ?? '')
                  .toLowerCase()
                  .startsWith(String(query ?? '').toLowerCase())
              )
              .slice(0, 5);
          },
          render: () => {
            let reactRenderer: ReactRenderer<MentionList, any>;
            let popup: any;

            return {
              onStart: (props: any) => {
                reactRenderer = new ReactRenderer(MentionList, {
                  props,
                  editor: props.editor,
                });

                popup = tippy('body', {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: reactRenderer.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: 'manual',
                  placement: 'bottom-start',
                });
              },
              onUpdate(props: any) {
                reactRenderer?.updateProps(props);

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect,
                });
              },
              onKeyDown(props: any) {
                if (props.event.key === 'Escape') {
                  popup[0]?.hide();

                  return true;
                }

                return !!reactRenderer?.ref?.onKeyDown(props);
              },
              onExit() {
                popup[0].destroy();
                reactRenderer?.destroy();
              },
            };
          },
        },
      }),
      Subscript,
      Superscript,
    ],
    content: userContent,
  });

  if (editor) {
    (editor as any).setOptions({ editable });
  }

  return editor;
};
