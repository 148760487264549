import { createContext, useContext } from 'react';

import type { DynamicFormContextProps as CtxType } from './DynamicFormProvider';

export const DynamicFormContext = createContext<CtxType>({} as CtxType);

export const useDynamicForm = () => {
  const context = useContext(DynamicFormContext);

  if (context === undefined) {
    throw new Error('useDynamicForm must be used within a DynamicFormProvider');
  }
  return context;
};
