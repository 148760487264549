import React from 'react';

import { getValueFromObjOrSimple, getValueOrUndefined } from '../helpers/getValue';
import type { InputFieldProps } from './InputField';
import InputField from './InputField';
import InputRadio from './InputRadio';

type Value = string | number;
type Option = {
  id: string;
  value: Value;
  labelText: string;
  descriptionText: string;
};

export interface InputRadioGroupProps extends InputFieldProps {
  isReadOnly?: boolean;
  onChange?: (value: any) => void;
  options?: Array<Value | Option>;
  value?: Value;
}

const InputRadioGroup: React.FC<InputRadioGroupProps> = ({
  className,
  descriptionText,
  errors,
  id,
  isRequired,
  isReadOnly,
  labelText,
  name,
  onChange,
  options,
  value,
}) => (
  <InputField
    className={className}
    descriptionText={descriptionText}
    errors={errors}
    id={id || name}
    isRequired={isRequired}
    labelText={labelText}
    name={name}
  >
    {options?.map((option) => (
      <InputRadio
        className="py-1"
        descriptionText={getValueOrUndefined(option, 'descriptionText')}
        errors={!!errors}
        id={getValueOrUndefined(option, 'id') || [name, option].join('.')}
        isChecked={value ? value === getValueFromObjOrSimple(option, 'value') : undefined}
        isRequired={isRequired}
        key={getValueFromObjOrSimple(option, 'value')}
        labelText={getValueFromObjOrSimple(option, 'labelText')}
        name={name}
        onChange={onChange}
        value={getValueFromObjOrSimple(option, 'value')}
        isDisabled={isReadOnly}
        readOnly={isReadOnly}
      />
    ))}
  </InputField>
);

export default InputRadioGroup;
