import type React from 'react';
import { useEffect, useRef, useState } from 'react';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';
import type { SchemaOf } from 'yup';

import type { GenericObject } from '../../@types/types';

type FormValuesPlaceholderType = GenericObject<unknown>;
type FieldValue = string | number | string[] | number[] | boolean;

type HandleSubmitFn = (event: React.FormEvent<HTMLFormElement>) => Promise<false | void>;
type HandleChangeFn = (eventOrValue: React.ChangeEvent<HTMLInputElement> | any, fieldName?: string) => void;
type GetFieldPropsFn = <T = FieldValue>(
  fields: string
) =>
  | {
      name: string;
      value: T;
      onChange: HandleChangeFn;
    }
  | GenericObject;

type FormError = {
  dataPath: string;
  path: string;
  message: string;
};

interface FormHookArgs<TData = FormValuesPlaceholderType> {
  initialState: TData;
  validationSchema: SchemaOf<TData>;
  validationOptions?: { abortEarly?: boolean } & GenericObject;
  onSubmit: (formValues: TData, event: React.FormEvent<HTMLFormElement>) => void;
  onBeforeChange?: (clonedFormValues: TData, name: string, value: unknown) => void;
}

interface FormHookPayload<TData = FormValuesPlaceholderType> {
  canSubmit: boolean;
  formError?: string;
  formErrors?: FormError[] | null;
  validationErrors?: string[];
  formValues?: TData;
  isLoading: boolean;
  isValid: boolean;
  getFieldProps: GetFieldPropsFn;
  onSubmit: HandleSubmitFn;
  setFormError: (error: string) => void;
  setFormErrors: (errors: FormError[]) => void;
  setFormValues: (values: TData) => void;
}

const useForm = <T = FormValuesPlaceholderType>({
  initialState,
  validationSchema,
  validationOptions,
  onSubmit,
  onBeforeChange,
}: FormHookArgs<T>): FormHookPayload<T> => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<T>(initialState);
  const [formError, setFormError] = useState<string | never>();
  const [formErrors, setFormErrors] = useState<FormError[] | null>();
  const [validationErrors, setValidationErrors] = useState([]);

  const hasSchema = validationSchema && Object.keys(validationSchema).length > 0;
  const isValid = hasSchema && validationSchema.isValidSync(formValues, validationOptions);
  const canSubmit = !isLoading && isValid;

  const mountedRef = useRef(true);

  const handleChange: HandleChangeFn = (eventOrValue, fieldName) => {
    const clonedFormValues = cloneDeep(formValues);
    const isCheckbox = eventOrValue?.target?.type === 'checkbox';
    const isChecked = eventOrValue?.target?.checked;
    const value = eventOrValue?.target?.value ?? eventOrValue;
    const name = eventOrValue?.target?.name ?? fieldName;

    set(clonedFormValues as any, name, isCheckbox ? isChecked : value);
    // TODO: fix any. The problematic "object" comes from lodash.set

    if (onBeforeChange) {
      onBeforeChange(clonedFormValues, name, value);
    }

    setFormValues(clonedFormValues);
  };

  const handleSubmit: HandleSubmitFn = async (event) => {
    setLoading(true);

    try {
      await onSubmit(formValues, event);

      if (!mountedRef.current) {
        return false;
      }

      return setLoading(false);
    } catch (error) {
      setLoading(false);

      return setFormError(get(error, 'message'));
    }
  };

  const getFieldProps: GetFieldPropsFn = (...args) => {
    if (args.length === 1) {
      const [name] = args;

      return { name, value: get(formValues, name, ''), onChange: handleChange };
    }

    return { ...args };
  };

  useEffect(() => {
    if (validationSchema) {
      try {
        validationSchema.validateSync(formValues, validationOptions);
        setValidationErrors([]);
      } catch (error: any) {
        // probably "ValidationError" from yup
        setValidationErrors(error?.errors);
      }
    }
  }, [formValues]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return {
    canSubmit,
    formValues,
    formError,
    formErrors,
    validationErrors,
    isLoading,
    isValid,
    onSubmit: handleSubmit,
    getFieldProps,
    setFormError,
    setFormErrors,
    setFormValues,
  };
};

export default useForm;
