import { useEffect, useState } from 'react';

import qs from 'qs';
import { useQuery } from 'react-query';

import type {
  Filter,
  SortFieldValueNormalizerFn,
  SortOrder,
  ToggleSortOrderDirectionFn,
} from '../../../../@types/types';
import { sortData } from '../../../helpers/sortData';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

export const useDataQuery = (
  queryKey: string,
  initialFilters: Filter[] = [],
  fetchData: (authToken: string) => Promise<any>,
  onSuccess: (data: any) => any = () => {}
) => {
  const [filters, setFilters] = useState(initialFilters);
  const [sortOrder, setSortOrder] = useState<SortOrder>({
    fieldName: 'created_at',
    sortDirection: 'desc',
    defaultFieldValue: '',
  });
  const [shouldFetchData, setShouldFetchData] = useState<boolean>(true);
  const [fetchedAt, setFetchedAt] = useState<string>(Date());

  useEffect(() => {
    if (shouldFetchData) {
      setFetchedAt(Date());
    }
  }, [shouldFetchData]);

  const { user } = useCurrentUser();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  const { data, status } = useQuery([queryKey, fetchedAt], () => fetchData(queryParams.authToken as string), {
    refetchOnWindowFocus: false,
    enabled: user?.attributes?.['custom:role'] === 'admin',
    onSuccess: (data) => {
      setShouldFetchData(false);
      onSuccess(data);
    },
  });

  const toggleSortOrderDirection: ToggleSortOrderDirectionFn =
    (fieldName: string, fieldValueNormalizer?: SortFieldValueNormalizerFn) => () => {
      const newSortDirection = fieldName !== sortOrder.fieldName || sortOrder.sortDirection !== 'desc' ? 'desc' : 'asc';
      setSortOrder({ ...sortOrder, fieldName, fieldValueNormalizer, sortDirection: newSortDirection });
      sortData(data, sortOrder);
    };

  sortData(data, sortOrder);

  return {
    data,
    sortOrder,
    status,
    setShouldFetchData,
    toggleSortOrderDirection,
    filters,
    setFilters,
  };
};
