import React, { useState } from 'react';

import type { UIInputValue } from '../../@types/types';
import { Controls } from '../containers/WysiwygEditor/components';
import { Editor as EditorContent } from '../containers/WysiwygEditor/components';
import { useEditor } from '../containers/WysiwygEditor/components/Editor/hooks/use-editor';

interface EditorProps {
  name: string;
  content: UIInputValue;
  onUpdate: (content: UIInputValue) => void;
}

const Editor: React.FC<EditorProps> = ({ name, content, onUpdate }) => {
  const [unformattedPaste, setUnformattedPaste] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const editor = useEditor({
    documentType: '',
    name,
    suggestionFields: [],
    editorContent: { [name]: content },
    editable: true,
    unformattedPaste,
    onUpdate,
    getPreviewState: () => false,
    getFormState: () => ({} as any),
  });

  const toggleUnformattedPaste = () => setUnformattedPaste(!unformattedPaste);

  return (
    <>
      {editor && (
        <Controls
          editor={editor}
          isDisabled={false}
          unformattedPaste={unformattedPaste}
          onUnformattedPasteClick={toggleUnformattedPaste}
        />
      )}
      <div className="relative overflow-y-auto rounded-lg bg-white border-1 shadow mb-2 border-gray-200">
        <EditorContent
          editor={editor}
          isDisabled={false}
          scrollPosition={scrollPosition}
          setScrollPosition={setScrollPosition}
        />
      </div>
    </>
  );
};

export default Editor;
