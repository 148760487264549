import React from 'react';

import type { NumberFormatValues, SourceInfo } from 'react-number-format';
import NumberFormat from 'react-number-format';

import Input from './Input';
import type { InputFieldProps } from './InputField';
import InputField from './InputField';
import InputReadOnly from './InputReadOnly';

export interface InputPercentageProps extends InputFieldProps {
  descriptionText?: string;
  id?: string;
  isAllowed?: (values: NumberFormatValues) => boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  labelText?: string;
  decimalScale?: number;
  onChange?: (floatValue: number | undefined, name?: string) => void;
  value?: number;
}

const InputPercentage: React.FC<InputPercentageProps> = ({
  className,
  descriptionText,
  decimalScale = 0,
  errors,
  id,
  isAllowed,
  isReadOnly,
  isRequired,
  labelText,
  name,
  onChange,
  value,
  ...props
}) => {
  const handleChange = ({ floatValue }: NumberFormatValues, _sourceInfo: SourceInfo) => {
    if (onChange) onChange(floatValue, name);
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
      errors={errors}
    >
      {!isReadOnly && (
        <div className="relative">
          <div className="absolute inset-y-0 right-0 flex items-center justify-center w-8">%</div>

          <NumberFormat
            allowNegative={false}
            className="text-right"
            customInput={Input}
            decimalScale={decimalScale}
            errors={errors}
            id={id || name}
            isAllowed={isAllowed}
            isNumericString
            isRequired={isRequired}
            name={name}
            onValueChange={handleChange}
            value={value}
          />
        </div>
      )}
      {isReadOnly && <InputReadOnly {...props} defaultValue={value} />}
    </InputField>
  );
};

export default InputPercentage;
