import React, { useEffect, useRef, useState } from 'react';

import { isEqual } from 'lodash';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import set from 'lodash.set';
import moment from 'moment';

import type { UIFormValues, UIInputValue } from '../../../../@types/types';
import { DynamicFormContext } from './DynamicFormContext';

type ReactEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

type HandleChangeArgs = [ReactEvent] | [UIInputValue, string];

export interface DynamicFormContextProps {
  formValues: UIFormValues;
  getFormState: () => UIFormValues;
  hasUnsavedChanges: boolean;
  onChange: (...args: HandleChangeArgs) => void;
  setFormValues: (values: UIFormValues) => void;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}

interface DynamicFormProviderProps {
  children: React.ReactNode;
  initialValues: UIFormValues;
}

export const DynamicFormProvider: React.FC<DynamicFormProviderProps> = ({ children, initialValues }) => {
  const [formValues, defaultSetFormValues] = useState<UIFormValues | never>(initialValues);
  /* We're using this trick for `MentionExtension` in order to be able to access the **current** form state, as opposed to the stale one living in a closure (which is the behaviour for `useState` state). */
  const formValuesRef = useRef<UIFormValues>({});
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  const setFormValues = (values: UIFormValues) => {
    defaultSetFormValues(values);
    formValuesRef.current = values;
  };

  useEffect(() => {
    if (initialValues) {
      setFormValues({ ...initialValues, ...getFormState() });
      setHasUnsavedChanges(false);
    }
  }, [initialValues]);

  /**
   * If `settlement_due_date` changes and `due_to_ed_date` is empty we want
   * to subtract 10 days and insert it as a default value
   */
  const runDueToEdDateCheck = (value: UIInputValue, name: string, nextFormValues: UIFormValues) => {
    if (name.includes('.settlement_due_date')) {
      const prefix = name.split('.settlement_due_date')[0];
      const dueToEdFieldName = `${prefix}.due_to_ed_date`;
      const dueToEdValue = get(nextFormValues, dueToEdFieldName);

      if (!dueToEdValue) {
        set(nextFormValues, dueToEdFieldName, moment(value).subtract(10, 'days').toISOString());
      }
    }
  };

  const handleChange: DynamicFormContextProps['onChange'] = (...args) => {
    const nextFormValues = cloneDeep(formValues);

    if (args.length === 1) {
      const [event] = args;

      set(nextFormValues!, event?.target.name, event?.target.value);
    }

    if (args.length === 2) {
      const [value, name] = args;
      set(nextFormValues!, name, value);
      runDueToEdDateCheck(value, name, nextFormValues!);
    }

    setFormValues(nextFormValues!);
    setHasUnsavedChanges(!isEqual(nextFormValues, initialValues));
  };

  const getFormState = () => formValuesRef.current;

  const value: DynamicFormContextProps = {
    formValues: formValues,
    getFormState,
    hasUnsavedChanges,
    onChange: handleChange,
    setFormValues,
    setHasUnsavedChanges,
  };

  return <DynamicFormContext.Provider value={value}>{children}</DynamicFormContext.Provider>;
};
