import React from 'react';

import { Auth } from '@aws-amplify/auth';
import get from 'lodash.get';
import { useNavigate, useMatch, useLocation } from 'react-router-dom';
import * as yup from 'yup';

import Button from '../../components/Button';
import Form from '../../components/Form';
import H3 from '../../components/H3';
import Icon from '../../components/Icon';
import InputText from '../../components/InputText';
import { parseParams } from '../../helpers/parseParams';
import useForm from '../../hooks/useForm';
import AuthLayout from './AuthLayout';

interface PasswordConditionProps {
  isValid: boolean;
  description: string;
}

type PasswordFormState = {
  newPassword: string;
  confirmNewPassword?: string;
};

const PasswordCondition: React.FC<PasswordConditionProps> = ({ isValid, description }) => (
  <div className="flex items-center mb-3">
    {!isValid && <Icon name="cross" className="fill-current mr-2 w-3 text-red-900" />}

    {isValid && <Icon name="checkmark" className="fill-current mr-2 w-3 text-green-900" />}

    <p>{description}</p>
  </div>
);

const checkMatch = function (this: any, value?: string): boolean {
  return !!(value && value.length >= 10 && value === this.parent.confirmNewPassword);
};

const conditions = {
  minimumCharacters: 'Must be a minimum of 10 characters.',
  uppercaseLowercase: 'Must contain upper and lower case letters.',
  numberMinimum: 'Must contain at least 1 number (between 0-9)',
  specialCharacter: 'Must contain at least 1 special character (@$!%*?&)',
  passwordMatch: 'Password confirmation must match password',
};

const PasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isReset = useMatch('/reset-password');
  const isSetup = useMatch('/setup-password');
  const isRegister = useMatch('/register');

  const { getFieldProps, onSubmit, validationErrors, canSubmit } = useForm<PasswordFormState>({
    initialState: { newPassword: '', confirmNewPassword: '' },
    onSubmit: async (formValues) => {
      if (isReset) {
        const { email, code } = parseParams(location.search);

        await Auth.forgotPasswordSubmit(email as string, code as string, formValues.newPassword);

        return navigate('/login');
      }

      if (isSetup) {
        const signInFormData = get(location, 'state') as { email: string; password: string };
        const user = await Auth.signIn(signInFormData.email.trim(), signInFormData.password);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(user, formValues.newPassword);

          return navigate('/login');
        }
      }

      if (isRegister) {
        const { inviteCode, userId } = parseParams(location.search);
        const user = await Auth.signIn(userId as string, inviteCode as string);

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(user, formValues.newPassword);

          return navigate('/login');
        }
      }

      return false;
    },
    validationOptions: { abortEarly: false },
    validationSchema: yup.object().shape({
      newPassword: yup
        .string()
        .min(10, conditions.minimumCharacters)
        .matches(/^(?=.*[A-Z])(?=.*[a-z])/, conditions.uppercaseLowercase)
        .matches(/^(?=.*[0-9])/, conditions.numberMinimum)
        .matches(/^(?=.*[!@#$%^&*()_+\-[\]{};':"\\|,.<>/?])/, conditions.specialCharacter)
        .test('match', conditions.passwordMatch, checkMatch)
        .required(),
      confirmNewPassword: yup.string(),
    }),
  });

  return (
    <AuthLayout>
      <Form onSubmit={onSubmit}>
        <H3 className="mb-4">{isRegister ? 'Register your account' : 'Create new password'}</H3>

        <p className="mb-6">For security we require that your password matches all of these requirements:</p>

        <div className="mb-6">
          {(Object.keys(conditions) as Array<keyof typeof conditions>).map((key) => {
            const condition = conditions[key];

            return (
              <PasswordCondition key={key} description={condition} isValid={!validationErrors?.includes(condition)} />
            );
          })}
        </div>

        <InputText
          className="mb-6"
          labelText={isRegister ? 'Password' : 'New Password'}
          placeholder="*********"
          type="password"
          {...getFieldProps('newPassword')}
        />

        <InputText
          className="mb-6"
          labelText={isRegister ? 'Confirm Password' : 'Confirm New Password'}
          placeholder="*********"
          type="password"
          {...getFieldProps('confirmNewPassword')}
        />

        <Button kind="primary" type="submit" isDisabled={!canSubmit}>
          {isRegister ? 'Register' : 'Create New Password'}
        </Button>
      </Form>
    </AuthLayout>
  );
};

PasswordCondition.defaultProps = {
  isValid: undefined,
};

export default PasswordForm;
