import React from 'react';

import get from 'lodash.get';
import moment from 'moment';

import type { GenericObject, UIContext, UISchema } from '../../../@types/types';
import type { InputCalendarProps } from '../InputCalendar';
import InputCalendar from '../InputCalendar';

type FormValues = GenericObject<string | number> & {
  contract: { inception_date: string };
};

const getDisabledDaysBefore = (schema: UISchema, ctx: UIContext) => {
  if ('ui:min:days:is:endorsement_effective_from' in schema) {
    const effectiveFromDate = get(ctx, 'contractData.submission.quote.effective_from');

    if (effectiveFromDate) {
      return moment(effectiveFromDate).toDate();
    }
  }

  if ('ui:min:days:is:inception_date' in schema) {
    const inceptionDate = get(ctx, 'contractData.submission.quote.inception_date');

    return moment(inceptionDate).toDate();
  }

  if ('ui:max:days:before:today' in schema) {
    return moment().subtract(schema['ui:max:days:before:today'], 'days').toDate();
  }

  return undefined;
};

const getDisabledDaysAfter = (schema: UISchema, ctx: UIContext, formValues: FormValues) => {
  if ('ui:max:days:is:expiry_date' in schema) {
    const expiryDate = get(ctx, 'contractData.submission.quote.expiry_date');

    return moment(expiryDate).toDate();
  }

  if ('ui:max:days:after:today' in schema) {
    return moment().add(schema['ui:max:days:after:today'], 'days').toDate();
  }

  if ('ui:max:days:is:inception_date:plus:months' in schema) {
    const inceptionDate = formValues?.contract?.inception_date;

    return moment(inceptionDate).add(schema['ui:max:days:is:inception_date:plus:months'], 'months').toDate();
  }

  return undefined;
};

const getInitialMonth = (schema: UISchema, ctx: UIContext) => {
  if ('ui:min:days:is:endorsement_effective_from' in schema) {
    const effectiveFromDate = get(ctx, 'contractData.submission.quote.effective_from');

    if (effectiveFromDate) {
      return moment(effectiveFromDate).toDate();
    }
  }

  if ('ui:min:days:is:inception_date' in schema) {
    const inceptionDate = get(ctx, 'contractData.submission.quote.inception_date');

    return moment(inceptionDate).toDate();
  }

  return undefined;
};

interface InputCalendarSchemaProps extends InputCalendarProps {
  ctx: UIContext;
  item: UISchema;
  formValues: FormValues;
}

const InputCalendarSchema: React.FC<InputCalendarSchemaProps> = ({ item: schema, ctx, value, ...props }) => (
  <InputCalendar
    disableDaysBefore={getDisabledDaysBefore(schema, ctx)}
    disableDaysAfter={getDisabledDaysAfter(schema, ctx, props.formValues)}
    dayPickerProps={{ initialMonth: getInitialMonth(schema, ctx) }}
    value={value}
    dateFormat={schema['ui:component:format'] as string}
    {...props}
  />
);

export default InputCalendarSchema;
