import OrderedListExtension from '@tiptap/extension-ordered-list';
// @ts-ignore
import type { CommandProps } from '@tiptap/react';
import type { Node, NodeType } from 'prosemirror-model';
import type { Selection } from 'prosemirror-state';

import { findFirstNodeAbove } from './helpers';

export type IconType = typeof iconList[number];
export const iconList = ['1', 'i', 'a', '1.1'] as const;

const iconType = (index: number): number => {
  const size = iconList.length;
  // We are not traversing 1.1 so we use (size - 1) here.
  return index % (size - 1);
};

const nextIconType = (icon: string): string => {
  switch (icon) {
    case '1.1':
      return 'a';
    case 'a':
      return 'i';
    case '1':
      return '1.1';
    default:
      return '1';
  }
};

const getIndent = (element: HTMLElement): number => {
  if (element.tagName === 'BODY') return 0;
  const tagName = element.tagName;
  const result = tagName === 'LI' ? 1 : 0;
  const parent = element.parentElement;
  return parent ? result + getIndent(parent) : result;
};

export const OrderedList = OrderedListExtension.extend({
  addAttributes() {
    const parent = this.parent ? this.parent() : {};
    return {
      ...parent,
      indent: {
        default: 0,
        parseHTML: (element: any) => {
          const parent = element.parentElement;
          const result = parent ? getIndent(parent) : 0;
          return result;
        },
      },
      // this is a type that is being set by the recalculate function
      // and is the "final" type that we see in the editor
      type: {
        default: '1',
        parseHTML: (element: any) => {
          const result = element.hasAttribute('type') ? element.getAttribute('type') : '1';
          return result;
        },
      },
      // this is a pre set type that is being used in the recalculate function
      // to know to ignore the depth we are being in
      'pre-set-type': {
        default: undefined,
        parseHTML: (element: any) => {
          return element.getAttribute('type') ?? undefined;
        },
      },
    };
  },

  // @ts-ignore: Unreachable code error - somehow ts does not like this
  addCommands() {
    const parent = this.parent ? this.parent() : {};
    return {
      ...parent,
      indentOrderedListRight: () => (args: CommandProps) => {
        args.chain().focus().sinkListItem('listItem').command(recalculate()).focus(args.state.selection.from).run();
        return false;
      },
      indentOrderedListLeft: () => (args: CommandProps) => {
        const node = findFirstNodeAbove(this.name, args.state.selection);
        if (node.node.attrs.indent && node.node.attrs.indent > 0) {
          args.chain().liftListItem('listItem').command(recalculate()).run();
        }
        args.commands.focus(args.state.selection.from);
        return;
      },
      setIconType: (icon: IconType) => (args: CommandProps) => {
        const node = findFirstNodeAbove(this.name, args.state.selection);
        args.tr.setNodeMarkup(node.before, node.node.type, { ...node.node.attrs, 'pre-set-type': icon, type: icon });
      },
    };
  },
});

// this function recalculates types that the tree should have
const recalculate =
  () =>
  (args: CommandProps): boolean => {
    const root = findRoot(args.state.selection);
    const tree = buildTree(root.root, root.before);
    decorate(tree, 0, undefined, args);
    args.dispatch && args.dispatch();
    return true;
  };

// assuming that there is an ordered list node above
const findRoot = ({
  $from,
}: Selection<any>): { root: Node<any> & { type: NodeType<any> & { name: 'orderedList' } }; before: number } => {
  let result: undefined | ReturnType<typeof findRoot>;
  for (let depth = $from.depth; depth >= 0; depth--) {
    let node = $from.node(depth);
    if (node.type.name === 'orderedList') {
      result = { root: node as ReturnType<typeof findRoot>['root'], before: $from.before(depth) };
    }
  }
  if (result === undefined) {
    throw Error('should not happen');
  }
  return result;
};

// assuming that root is an orderedList
const buildTree = (root: Node<any> & { type: { name: 'orderedList' } }, start: number): Tree => {
  const result = {
    from: start,
    to: start + root.content.size,
    children: [],
    presetType: root.attrs['pre-set-type'],
    start: root.attrs['start'],
  };

  // assuming that descendants are done from top to bottom
  root.descendants((node, pos) => {
    if (node.type.name === 'orderedList') {
      const rpos = root.resolve(pos);
      const start1 = start + pos + (rpos.start() - rpos.before()); // start + offset from parent + size of the token start
      const end = start1 + node.content.size; // node.content.size for the size of the content itself
      const presetType = node.attrs['pre-set-type'];
      const child = { from: start1, to: end, children: [], presetType, start: 1 };
      insertTree(result, child);
    }
  });
  return result;
};

const insertTree = (root: Tree, child: Tree): boolean => {
  // assuming that trees form correct brackets expression
  // ie there is no ([)]
  if (root.from < child.from && root.to > child.from) {
    let inserted = false;
    root.children.map((ch) => {
      if (!inserted) {
        inserted = insertTree(ch, child);
      }
    });
    if (!inserted) {
      root.children.push(child);
    }
    return true;
  }
  return false;
};

type Tree = { from: number; to: number; children: Tree[]; presetType: string | undefined; start: number | undefined };

// sets type for a given tree
// the difference between this and updateAttributes is
// that this function sets a type only for the beginning of the selection
// not for all of the nodes in that selection
const setType = (tree: Tree, depth: number, icon: string, args: CommandProps) => {
  args.tr.setNodeMarkup(tree.from, undefined, {
    type: tree.presetType ?? icon,
    indent: depth,
    'pre-set-type': tree.presetType,
    start: tree.start,
  });
};

export const decorate = (tree: Tree, depth = 0, icon: string | undefined, args: CommandProps) => {
  const current = tree.presetType ?? icon ?? iconList[iconType(depth)];
  setType(tree, depth, current, args);
  tree.children.forEach((x) => decorate(x, depth + 1, nextIconType(current), args));
};
