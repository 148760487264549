import React, { useState } from 'react';

import clsx from 'clsx';

import type { CustomSvgComponent } from '../../@types/types';

const Svg: CustomSvgComponent = ({ stroke, strokeWidth, fill }) => (
  <svg width="16" height="16" className="self-center">
    <circle cx="8" cy="8" r="7" stroke={stroke} strokeWidth={strokeWidth} fill="none" />
    <circle cx="8" cy="8" r="4" stroke="none" strokeWidth="0" fill={fill} />
  </svg>
);

interface InputRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
  descriptionText?: string;
  errors?: boolean;
  id?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  labelText?: string;
}

const InputRadio: React.FC<InputRadioProps> = ({
  className,
  descriptionText,
  errors,
  id,
  isChecked,
  isDisabled,
  isRequired,
  labelText,
  name,
  value,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const stroke = (errors && '#f03e3e') || (isDisabled && '#CED4DA') || (isFocused && '#339AF0') || '#868E96';
  const strokeWidth = isFocused || errors ? '2' : '1';
  const fill = (isChecked && !isDisabled && '#339AF0') || (isChecked && isDisabled && '#CED4DA') || 'none';

  return (
    <label
      className={clsx(
        'relative flex items-start cursor-pointer content-center',
        isDisabled && 'text-gray-700',
        className
      )}
      htmlFor={id}
    >
      <Svg stroke={stroke} strokeWidth={strokeWidth} fill={fill} />
      <div className="ml-3">
        <div className="text-sm">{labelText}</div>
        {descriptionText && <p className={clsx('text-sm mt-2', !isDisabled && 'text-gray-800')}>{descriptionText}</p>}
      </div>
      <input
        className="absolute opacity-0 -z-10"
        disabled={isDisabled}
        checked={isChecked}
        id={id}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type="radio"
        value={value}
        required={isRequired}
        {...props}
      />
    </label>
  );
};

export default InputRadio;
