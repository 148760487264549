import type { UISelectionType } from '../../../../@types/types';

export const clauseTypes: UISelectionType[] = [
  { name: 'Condition', value: 'Condition' },
  { name: 'Endorsement', value: 'Endorsement' },
  { name: 'Exclusion', value: 'Exclusion' },
  { name: 'Warranty', value: 'Warranty' },
  { name: 'Wording', value: 'Wording' },
];

export const clauseSingularToPluralMapping = {
  Condition: 'Conditions',
  Endorsement: 'Endorsements',
  Exclusion: 'Exclusions',
  Warranty: 'Warranties',
  Wording: 'Wordings',
};

export const defaultModalTitle = 'Create clause';
