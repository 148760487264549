import React, { useContext } from 'react';

import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';

import type { Clause, ClauseType, UIInputValue } from '../../../../@types/types';
import Editor from '../../../components/Editor';
import InputCheckbox from '../../../components/InputCheckbox';
import InputSelect from '../../../components/InputSelect';
import InputText from '../../../components/InputText';
import { createClauseInLibrary } from '../../../mutations';
import VerticalTabsContext from '../../WysiwygManager/contexts/VerticalTabsContext';
import ClauseLibraryContext from '../ClauseLibraryContext';
import { clauseTypes } from '../constants';
import { useClause } from '../useClause';

export const AddClause = () => {
  const {
    clauseDescription,
    clauseType,
    clauseBody,
    shouldShareClause,
    setClauseDescription,
    setClauseType,
    setClauseBody,
    handleCloseModal,
    setShouldShareClause,
    setShouldFetchClauseLibrary,
  } = useContext(ClauseLibraryContext);

  const { entityClausesState } = useContext(VerticalTabsContext);
  const { persistClauses } = useClause();

  const handleChangeClauseDescription = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setClauseDescription(target.value);
  const handleChangeClauseType = (type: UIInputValue) => setClauseType(type);
  const handleChangeClauseBody = (body: UIInputValue) => setClauseBody(body);
  const handleShouldShareClause = () => setShouldShareClause(!shouldShareClause);
  const handleAddClauseToSubmission = async () => {
    try {
      const newClause: Clause = {
        description: clauseDescription,
        type: clauseType as ClauseType,
        content: clauseBody ?? '',
        id: uuidv4(),
        index: entityClausesState.length,
        created_at: new Date().toISOString(),
      };
      console.log('entityClausesState before persistClauses:', entityClausesState);
      const newClauses = [...entityClausesState, newClause];
      persistClauses(newClauses, newClause);
      handleCloseModal();
      if (shouldShareClause) {
        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        await createClauseInLibrary(newClause, queryParams.authToken as string);
        setShouldFetchClauseLibrary(true);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <InputText
        labelText="Clause name"
        isRequired
        value={clauseDescription}
        onChange={handleChangeClauseDescription}
      />
      <InputSelect
        labelText="Type"
        value={clauseType}
        options={clauseTypes}
        onChange={handleChangeClauseType}
        name="clauseType"
      />
      <div className="add-submission-clause">
        <Editor name="clause" content={clauseBody ?? ''} onUpdate={handleChangeClauseBody} />
      </div>
      <InputCheckbox
        labelText="Add to central clause library"
        onChange={handleShouldShareClause}
        isChecked={shouldShareClause}
        value={String(shouldShareClause)}
      />
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          disabled={!clauseDescription || !clauseBody}
          onClick={handleAddClauseToSubmission}
          type="button"
          className="disabled:opacity-50 disabled:cursor-not-allowed focus:ring-blue-500 bg-blue-600 hover:bg-blue-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
        >
          Create
        </button>
        <button
          onClick={handleCloseModal}
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>
    </>
  );
};
