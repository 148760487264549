import axios from 'axios';

import type { TemplateShort } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const fetchTemplates = async (authToken: string): Promise<TemplateShort[]> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'get',
    url: `${process.env.REACT_APP_API_URL}/templates${auth.authProvider === 'al' ? '-spa-al' : ''}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
  });

  return response.data;
};
