import React from 'react';

import clsx from 'clsx';

const H3: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({ className, children, ...props }) => (
  <h3 className={clsx('text-xl leading-relaxed font-semibold', className)} {...props}>
    {children}
  </h3>
);

export default H3;
