import React, { useState } from 'react';

import clsx from 'clsx';

import type { CustomSvgComponent } from '../../@types/types';

const Svg: CustomSvgComponent = ({ stroke, strokeWidth, fill }) => (
  <svg width="24" height="24">
    <rect fill="#fff" height="22" rx="1.5" stroke={stroke} strokeWidth={strokeWidth} width="22" x="1" y="1" />
    <path
      fill={fill}
      d="M9.812 14.415l-2.667-2.797-.18-.19-.182.19-.964 1.011-.164.173.164.172 3.812 3.999.181.19.18-.19 8.189-8.589.164-.172-.164-.173-.964-1.011-.181-.19-.181.19-7.043 7.387z"
    />
  </svg>
);

interface InputCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  className?: string;
  descriptionText?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  labelText?: string;
  name?: string;
  value?: string;
  errors?: string | string[]; //TODO: not implemented
  isRequired?: boolean;
}

const InputCheckbox: React.FC<InputCheckboxProps> = ({
  className,
  descriptionText,
  id,
  isChecked,
  isDisabled,
  isRequired,
  labelText,
  name,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const stroke = (isDisabled && '#CED4DA') || (isFocused && '#339AF0') || '#868E96';
  const strokeWidth = isFocused ? '2' : '1';
  const fill = (isChecked && !isDisabled && '#339AF0') || (isChecked && isDisabled && '#CED4DA') || 'none';

  return (
    <label
      className={clsx('relative flex items-start cursor-pointer', isDisabled && 'text-gray-700', className)}
      htmlFor={id}
    >
      <Svg stroke={stroke} strokeWidth={strokeWidth} fill={fill} />

      <div className="ml-3">
        <div>{labelText}</div>
        {descriptionText && <p className={clsx('text-sm mt-2', !isDisabled && 'text-gray-800')}>{descriptionText}</p>}
      </div>

      <input
        required={isRequired}
        checked={Boolean(isChecked)}
        className="absolute opacity-0 -z-10"
        disabled={isDisabled}
        id={id}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        type="checkbox"
        {...props}
      />
    </label>
  );
};

export default InputCheckbox;
