import React, { useContext, useEffect, useState } from 'react';

import clsx from 'clsx';
import qs from 'qs';

import type { UIInputValue, ClauseLibrary as ClauseLibraryType } from '../../../@types/types';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { fetchClauseLibrary } from '../../queries';
import VerticalTabsContext from '../WysiwygManager/contexts/VerticalTabsContext';
import ClauseLibraryContext from './ClauseLibraryContext';
import { AddClause, EditClause, SelectOrCreate, EntityClauses } from './components';
import { defaultModalTitle } from './constants';
import { useClause } from './useClause';

export interface ClauseLibraryContextValue {
  clauseLibrary: ClauseLibraryType;
  clauseDescription: string;
  clauseType: UIInputValue;
  clauseBody: UIInputValue;
  clauseDeletedAt: string | null;
  shouldShareClause: boolean;
  clauseId: string;
  clauseValue: string;
  setClauseDescription: (description: string) => void;
  setClauseType: (type: UIInputValue) => void;
  setClauseBody: (body: UIInputValue) => void;
  handleCloseModal: () => void;
  setShouldShareClause: (flag: boolean) => void;
  setShouldFetchClauseLibrary: (flag: boolean) => void;
  setClauseId: (id: string) => void;
  setIsAddClause: (flag: boolean) => void;
  setIsEditClause: (flag: boolean) => void;
  setIsModalOpen: (flag: boolean) => void;
  setModalTitle: (title: string) => void;
  setClauseValue: (value: string) => void;
  setClauseLibrary: (clauseLibrary: ClauseLibraryType) => void;
}

const ClauseLibrary = () => {
  const { entityClausesState } = useContext(VerticalTabsContext);
  const [clauseLibrary, setClauseLibrary] = useState<ClauseLibraryType>([]);
  const [clauseId, setClauseId] = useState<string>('');
  const [clauseValue, setClauseValue] = useState<string>('');
  const [clauseDescription, setClauseDescription] = useState<string>('');
  const [clauseType, setClauseType] = useState<UIInputValue>('');
  const [clauseBody, setClauseBody] = useState<UIInputValue>('');
  const [clauseDeletedAt, setClauseDeletedAt] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>(defaultModalTitle);
  const [isAddClause, setIsAddClause] = useState<boolean>(false);
  const [isEditClause, setIsEditClause] = useState<boolean>(false);
  const [shouldShareClause, setShouldShareClause] = useState<boolean>(false);
  const [shouldFetchClauseLibrary, setShouldFetchClauseLibrary] = useState<boolean>(true);
  const { isClausePersistInProgress } = useClause();

  useEffect(() => {
    if (!shouldFetchClauseLibrary) {
      return;
    }
    (async () => {
      try {
        const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });
        const clauseLibraryData = await fetchClauseLibrary(queryParams.authToken as string);
        clauseLibraryData?.sort((a, b) => {
          return a.description.trim() > b.description.trim() ? 1 : -1;
        });
        setClauseLibrary(clauseLibraryData.filter((i) => !i.deleted_at));
      } catch (e) {
        console.log(e);
      } finally {
        setShouldFetchClauseLibrary(false);
      }
    })();
  }, [shouldFetchClauseLibrary]);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalTitle(defaultModalTitle);
    setIsAddClause(false);
    setIsEditClause(false);
    setShouldShareClause(false);
    setClauseId('');
    setClauseValue('');
    setClauseDescription('');
    setClauseType('');
    setClauseBody('');
    setClauseDeletedAt(null);
  };

  const contextValue: ClauseLibraryContextValue = {
    clauseLibrary,
    handleCloseModal,
    clauseId,
    clauseValue,
    clauseDescription,
    clauseType,
    clauseBody,
    clauseDeletedAt,
    shouldShareClause,
    setIsAddClause,
    setShouldShareClause,
    setModalTitle,
    setIsModalOpen,
    setIsEditClause,
    setClauseId,
    setClauseValue,
    setClauseDescription,
    setClauseType,
    setClauseBody,
    setClauseLibrary,
    setShouldFetchClauseLibrary,
  };

  const isWysiwyg = isAddClause || isEditClause;

  return (
    <ClauseLibraryContext.Provider value={contextValue}>
      <div className="text-xs py-4 font-medium px-4 uppercase text-gray-500 tracking-wide border-b border-gray-200">
        Clauses&nbsp;
        <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
          {entityClausesState.length}
        </span>
      </div>
      <div className="bg-white p-6 pl-4">
        <Button
          onClick={handleOpenModal}
          isDisabled={isClausePersistInProgress}
          className="disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Add clause
        </Button>
      </div>
      <EntityClauses />
      <Modal
        className={clsx(`w-full lg:w-1/2 sm:w-5/6`, isWysiwyg && ' h-screen')}
        open={isModalOpen}
        onClose={handleCloseModal}
        title={modalTitle}
      >
        {isAddClause && <AddClause />}
        {isEditClause && <EditClause />}
        {!isAddClause && !isEditClause && <SelectOrCreate />}
      </Modal>
    </ClauseLibraryContext.Provider>
  );
};

export default ClauseLibrary;
