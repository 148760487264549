import type { SortOrder } from '../../@types/types';

export const sortData = (data: undefined | any[], sortOrder: SortOrder) => {
  data?.sort((a: any, b: any) => {
    const { fieldName, sortDirection, defaultFieldValue = '', fieldValueNormalizer } = sortOrder;
    const firstField = fieldValueNormalizer
      ? fieldValueNormalizer(a[fieldName] ?? defaultFieldValue)
      : a[fieldName] ?? defaultFieldValue;
    const secondField = fieldValueNormalizer
      ? fieldValueNormalizer(b[fieldName] ?? defaultFieldValue)
      : b[fieldName] ?? defaultFieldValue;
    if (sortDirection === 'desc') {
      if (firstField < secondField) {
        return 1;
      }
      if (firstField > secondField) {
        return -1;
      }
    } else if (sortDirection === 'asc') {
      if (firstField < secondField) {
        return -1;
      }
      if (firstField > secondField) {
        return 1;
      }
    }

    return 0;
  });
};
