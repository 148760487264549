import React from 'react';

import clsx from 'clsx';

interface ToggleProps {
  value?: string | number;
  className: string;
  labelText: string;
  onClick: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const Toggle: React.FC<ToggleProps> = ({ value, onClick, className, labelText }) => (
  <div className={clsx('flex items-center cursor-pointer', className)} onClick={onClick}>
    <div className={clsx('w-8 h-4 rounded-full bg-gray-200 relative transition', value && 'bg-indigo-600')}>
      <div
        className={clsx(
          'w-5 h-5 bg-white absolute -mt-0.5 rounded-full border border-gray-400 shadow-sm transition',
          !value && '-left-1.5',
          value && '-right-1.5'
        )}
      />
    </div>

    {labelText && <p className="ml-3">{labelText}</p>}
  </div>
);

export default Toggle;
