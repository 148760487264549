import React from 'react';

import type { NumberFormatValues, SourceInfo } from 'react-number-format';
import NumberFormat from 'react-number-format';

import type { UIOnChangeFn } from '../../../@types/types';
import Input from '../Input';
import type { InputFieldProps } from '../InputField';
import InputField from '../InputField';
import InputReadOnly from '../InputReadOnly';

interface InputNumberSchemaProps extends InputFieldProps {
  defaultValue?: string | number;
  isReadOnly: boolean;
  item: any;
  onChange: UIOnChangeFn<number | string>;
  value: string | number;
}

const InputNumberSchema: React.FC<InputNumberSchemaProps> = ({
  className,
  descriptionText,
  errors,
  id,
  isRequired,
  labelText,
  name = 'number',
  noErrorLabel,
  defaultValue,
  isReadOnly,
  item: schema,
  value,
  onChange,
}) => {
  const handleChange = ({ floatValue }: NumberFormatValues, _sourceInfo: SourceInfo) => {
    if (onChange) {
      onChange(floatValue ?? '', name);
    }
  };
  const decimalScale = 'ui:decimal_scale' in schema ? schema['ui:decimal_scale'] : 0;

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      id={id || name}
      isRequired={isRequired}
      isDisabled={isReadOnly}
      labelText={labelText}
      name={name}
      noErrorLabel={noErrorLabel}
    >
      {isReadOnly ? (
        <InputReadOnly
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          name={name}
          value={`${value}${schema.suffix ?? ''}`}
        />
      ) : (
        <NumberFormat
          allowNegative={false}
          aria-describedby={descriptionText ? `${name}Description` : undefined}
          className="aui-input"
          customInput={Input}
          decimalScale={decimalScale ?? 0}
          defaultValue={defaultValue}
          errors={errors}
          id={id || name}
          max={schema.maximum}
          min={schema.minimum}
          maxLength={22}
          name={name}
          onValueChange={handleChange}
          thousandSeparator
          value={value}
          suffix={schema.suffix ?? ''}
        />
      )}
    </InputField>
  );
};

export default InputNumberSchema;
