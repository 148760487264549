import { useEffect } from 'react';

import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';
import unset from 'lodash.unset';

import type { S3Schema, UIFormValues } from '../../@types/types';

type SetFormValuesFn = (values: UIFormValues) => void;
type GetFormStateFn = { (): UIFormValues; (): any };

export const useBermudaMrcEffects = (
  _schema: S3Schema,
  formValues: UIFormValues,
  setFormValues: SetFormValuesFn,
  getFormState: GetFormStateFn
): void => {
  useEffect(() => {
    if (!formValues.situation_heading_name) {
      const nextFormValues = cloneDeep(getFormState());
      set(nextFormValues, 'situation_heading_name', 'Territory');
      setFormValues(nextFormValues);
    }
  }, []);

  useEffect(() => {
    if (
      Array.isArray(formValues.endorsements) &&
      formValues.endorsements.includes('NMA 2914 Electronic Data Endorsement A') &&
      !formValues.nma_2914_electronic_data_cost
    ) {
      const nextFormValues = cloneDeep(getFormState());
      set(nextFormValues, 'nma_2914_electronic_data_cost', '1000000');
      setFormValues(nextFormValues);
    }
  }, [formValues.endorsements]);

  useEffect(() => {
    const nextFormValues = cloneDeep(getFormState());
    if (formValues.situation_heading_name !== 'Other') {
      unset(nextFormValues, 'situation_heading_name_other');
      unset(nextFormValues, 'other');
      unset(nextFormValues, 'other_text');
    }
    if (formValues.situation_heading_name !== 'Territory') {
      unset(nextFormValues, 'territory');
      unset(nextFormValues, 'territory_text');
    }
    if (formValues.situation_heading_name !== 'Situation') {
      unset(nextFormValues, 'situation');
      unset(nextFormValues, 'situation_text');
    }
    setFormValues(nextFormValues);
  }, [formValues.situation_heading_name]);

  useEffect(() => {
    if (formValues.territory !== 'Free text box') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'territory_text');
      setFormValues(nextFormValues);
    }
  }, [formValues.territory]);

  useEffect(() => {
    if (formValues.situation !== 'Free text box') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'situation_text');
      setFormValues(nextFormValues);
    }
  }, [formValues.situation]);

  useEffect(() => {
    if (formValues.other !== 'Free text box') {
      const nextFormValues = cloneDeep(getFormState());
      unset(nextFormValues, 'other_text');
      setFormValues(nextFormValues);
    }
  }, [formValues.other]);
};
