import axios from 'axios';

import type { ServerResponse } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const createPdfDocument = async (
  id: string,
  authToken: string,
  isTemplate: boolean
): Promise<ServerResponse<{ url: string }>> => {
  const auth = await getRequestAuth(authToken);

  let url = `${process.env.REACT_APP_API_URL}/submissions-spa-${auth.authProvider}/${id}/pdf/create`;
  if (isTemplate) {
    url = `${process.env.REACT_APP_API_URL}/templates-spa-${auth.authProvider}/${id}/pdf/create`;
  }

  const response = await axios({
    method: 'post',
    url,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data: {},
  });

  return response.data;
};
