import React from 'react';

import withPrivateRoute from '../containers/Auth/PrivateRoute';

const NotFound: React.FC = () => (
  <div className="flex justify-center items-center h-96 pt-96">
    <h1 className="mr-5 py-3 pr-4 text-2xl font-semibold border-r-2">404</h1>
    <div className="text-lg">Page not found</div>
  </div>
);

export const NotFoundPage = withPrivateRoute({})(NotFound);
