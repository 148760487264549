// @ts-ignore
import { Extension } from '@tiptap/core';
// @ts-ignore
import type { SingleCommands } from '@tiptap/react';
// @ts-ignore
import { getNodeAttributes } from '@tiptap/react';

export interface IndentOptions {
  types: string[];
  defaultLevel: number;
}

export const Indent = Extension.create<IndentOptions>({
  name: 'indent',
  priority: 97,

  addOptions() {
    return {
      types: [
        'table', // to deal with Tab shortcut as well
        'paragraph',
        'li',
      ],
      defaultLevel: 0,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          level: {
            default: this.options.defaultLevel,
            parseHTML: (element: any) => {
              const level = element.getAttribute('level');
              if (level === undefined || level === null) {
                return this.options.defaultLevel;
              }
              return parseInt(level, 10);
            },
            renderHTML: (attributes: any) => {
              if (attributes.level === this.options.defaultLevel || !attributes.indent) {
                return {};
              }

              return {
                style: `padding-left: ${attributes.level * 1.677777}em`,
                level: attributes.level,
                indent: attributes.indent,
              };
            },
          },
          indent: {
            default: false,
            parseHTML(element: any) {
              const indent = element.getAttribute('indent');
              return indent;
            },
            renderHTML(_attributes: any) {
              return {};
            },
          },
        },
      },
    ];
  },

  // @ts-ignore: Unreachable code error - somehow ts does not like this
  addCommands() {
    return {
      indentLeftOrGoToPreviousCell:
        () =>
        ({ commands }: { commands: SingleCommands }) => {
          if (!commands.goToPreviousCell()) {
            // @ts-ignore: defined below
            return commands.indentLeft();
          }
        },
      indentRightOrGoToNextCell:
        () =>
        ({ commands }: { commands: SingleCommands }) => {
          if (!commands.goToNextCell()) {
            // @ts-ignore: defined below
            return commands.indentRight();
          }
        },
      indentRight:
        () =>
        ({ commands }: { commands: SingleCommands }) => {
          const isListItemActive = this.editor.isActive('listItem');
          if (isListItemActive) {
            // @ts-ignore: Unreachable code error - somehow ts does not like this
            return commands.indentOrderedListRight();
          }
          return this.options.types.every((type: string) => {
            if (type === 'table' || type === 'li') {
              return true;
            }
            const attrs = getNodeAttributes(this.editor.state, type);
            const level = attrs.level ?? 0;
            return commands.updateAttributes(type, { level: level + 1, indent: true });
          });
        },
      indentLeft:
        () =>
        ({ commands }: { commands: SingleCommands }) => {
          const isListItemActive = this.editor.isActive('listItem');
          if (isListItemActive) {
            // @ts-ignore: Unreachable code error - somehow ts does not like this
            return commands.indentOrderedListLeft();
          }
          return this.options.types.every((type: string) => {
            if (type === 'table' || type === 'li') {
              return true;
            }
            const attrs = getNodeAttributes(this.editor.state, type);
            const level = attrs.level ?? 0;
            const finalLevel = level <= 0 ? 0 : level - 1;
            return commands.updateAttributes(type, { level: finalLevel, indent: true });
          });
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => {
        // @ts-ignore: added above
        this.editor.commands.indentRightOrGoToNextCell();
        return this.editor.commands.focus();
      },
      'Shift-Tab': () => {
        // @ts-ignore: added above
        this.editor.commands.indentLeftOrGoToPreviousCell();
        return this.editor.commands.focus();
      },
    };
  },
});
