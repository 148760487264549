import React from 'react';

import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';

import type { UIChildrenMappingProps, UIMapChildrenFn, S3SchemaProperties } from '../../../@types/types';
import Button from '../Button';

export interface RepeaterProps extends UIChildrenMappingProps {
  className: string;
  value: string | number;
  id: string;
  ownKey: string;
  item: { items: S3SchemaProperties; title: string };
  mapChildren: UIMapChildrenFn;
}

const Repeater: React.FC<RepeaterProps> = ({
  className,
  formValues,
  value,
  id,
  item,
  ownKey,
  validationErrors,
  showQuestionKey,
  onChange,
  mapChildren,
  setFormValues,
}) => {
  const hasInitialValue = value && Array.isArray(value) && value.length > 0;
  const initialItems = hasInitialValue ? value : [''];

  const handleAdd = () => {
    const cloned = cloneDeep(formValues);

    set(cloned, ownKey, [...initialItems, '']);
    setFormValues(cloned);
  };

  const handleRemove = (itemIndex: number) => () => {
    const cloned = cloneDeep(formValues);
    const filtered = initialItems.filter((v, index) => index !== itemIndex);

    set(cloned, ownKey, filtered);
    setFormValues(cloned);
  };

  const schemaItem = item.items.type === 'object' ? item.items : { properties: { [id]: item.items } };

  return (
    <div className={className}>
      {initialItems.map((lineItem, index) => {
        const key = `${ownKey}[${index}]`;

        return (
          <div className="flex" key={index.toString()}>
            {mapChildren({
              parentSchema: schemaItem,
              formValues,
              parentKey: key,
              onChange,
              setFormValues,
              useParentKey: true,
              validationErrors,
              showQuestionKey,
            })}

            {initialItems.length > 1 && (
              <Button kind="danger-secondary" onClick={handleRemove(index)} className="mt-8 ml-8">
                Remove
              </Button>
            )}
          </div>
        );
      })}

      <Button onClick={handleAdd}>Add</Button>
    </div>
  );
};

export default Repeater;
