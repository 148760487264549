import { useEffect, useState } from 'react';

import { Auth } from '@aws-amplify/auth';

import type { AmplifyUser } from '../../@types/types';

interface CurrentUserHook {
  user: AmplifyUser | null;
}

export const useCurrentUser = (): CurrentUserHook => {
  const [user, setUser] = useState<AmplifyUser | null>(null);

  const authenticate = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user);
    } catch (e: unknown) {
      console.log(e);
    }
  };

  useEffect(() => {
    authenticate();
  }, []);

  return { user };
};
