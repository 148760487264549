import React from 'react';

import { currencyFormatter } from '../../helpers/formatters';
import type { InputSelectProps } from '../InputSelect';
import InputSelect from '../InputSelect';

interface InputSelectCurrencyProps extends InputSelectProps {
  item: { enum: string[] };
}

const InputSelectCurrency: React.FC<InputSelectCurrencyProps> = ({ item, ...props }) => (
  <InputSelect {...props} options={item.enum?.map((value) => ({ name: value, value }))} formatter={currencyFormatter} />
);

export default InputSelectCurrency;
