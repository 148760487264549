import React from 'react';

import type { S3Schema, UIChildrenMappingProps, UIMapChildrenFn } from '../../../@types/types';

interface GroupProps extends UIChildrenMappingProps {
  item: S3Schema;
  mapChildren: UIMapChildrenFn;
  ownKey: string;
}

const Group: React.FC<GroupProps> = ({
  formValues,
  item,
  mapChildren,
  ownKey,
  onChange,
  setFormValues,
  validationErrors,
  ctx,
  showQuestionId,
  showQuestionKey,
}) => {
  return (
    <div>
      {mapChildren({
        parentSchema: item,
        formValues,
        parentKey: ownKey,
        onChange,
        setFormValues,
        validationErrors,
        ctx,
        showQuestionId,
        showQuestionKey,
      })}
    </div>
  );
};

export default Group;
