import React, { useEffect } from 'react';

import clsx from 'clsx';

import type { UIComponentBase } from '../../@types/types';

type BannerColour = 'blue' | 'green' | 'orange' | 'red' | 'white';
type BannerVariants = Record<BannerColour, string>;

const variants: BannerVariants = {
  blue: ' border-blue-600',
  green: 'border-green-700',
  orange: 'border-orange-700',
  red: 'border-red-1000',
  white: 'border-gray-400',
};

interface BannerProps extends UIComponentBase {
  color: BannerColour;
  headingText?: string;
  cb?: () => void;
}

const Banner: React.FC<BannerProps> = ({ className, children, color = 'green', headingText, cb }) => {
  useEffect(() => {
    if (cb) {
      const timeout = setTimeout(cb, 3000);
      return () => clearTimeout(timeout);
    }
  });

  return (
    <div className={clsx('py-4 px-4 rounded border-solid shadow border-t-4 bg-white z-10', variants[color], className)}>
      {headingText && <p className="font-semibold text-gray-900">{headingText}</p>}
      <div className="mt-2 text-gray-800">{children}</div>
    </div>
  );
};

export default Banner;
