import { useContext } from 'react';

import type { Clause } from '../../../@types/types';
import VerticalTabsContext from '../WysiwygManager/contexts/VerticalTabsContext';

export function useClause() {
  const {
    setEntityClausesState,
    mutateSubmission,
    mutateTemplate,
    isTemplate,
    isMutateSubmissionInProgress,
    isMutateTemplateInProgress,
  } = useContext(VerticalTabsContext);

  function persistClauses(
    newClauses: Clause[],
    newClause: Clause | null,
    clauseReorder?: { sourceIndex?: number; targetIndex?: number }
  ) {
    setEntityClausesState(newClauses);
    if (isTemplate) {
      mutateTemplate({
        clause: newClause ? { id: newClause.id, index: newClause.index, deleted_at: newClause.deleted_at } : newClause,
        clauseReorder,
      });
    } else {
      mutateSubmission({ clause: newClause, clauseReorder });
    }
  }

  return {
    persistClauses,
    isClausePersistInProgress: isMutateSubmissionInProgress || isMutateTemplateInProgress,
  };
}
