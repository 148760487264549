import React from 'react';

import moment from 'moment';
import 'moment/locale/en-gb';
import type { DayModifiers, DayPickerProps } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils from 'react-day-picker/moment';

import type { UIOnChangeFn } from '../../@types/types';
import Input from './Input';
import type { InputFieldProps } from './InputField';
import InputField from './InputField';
import InputReadOnly from './InputReadOnly';

import './InputCalendarRDP.css';

const { formatDate, parseDate } = MomentLocaleUtils;

export interface InputCalendarProps extends InputFieldProps {
  isReadOnly?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  labelText?: string;
  placeholder?: string;
  dateFormat?: string;
  dayPickerProps?: DayPickerProps;
  descriptionText?: string;
  disableDaysAfter?: Date;
  disableDaysBefore?: Date;
  value?: string;
  onChange?: UIOnChangeFn;
}

const InputCalendar: React.FC<InputCalendarProps> = ({
  className,
  dayPickerProps,
  descriptionText,
  errors,
  id,
  isDisabled = false,
  isReadOnly,
  isRequired,
  labelText,
  disableDaysAfter,
  disableDaysBefore,
  name = 'calendar',
  onChange,
  placeholder = 'DD/MM/YYYY',
  size: _size = 'normal',
  value,
  dateFormat = 'DD/MM/YYYY',
  ...props
}) => {
  const valueDate = value ? moment.utc(value).format(dateFormat) : undefined;

  const handleDayChange = (day: Date | undefined, _dayModifiers: DayModifiers, _dayPickerInput: DayPickerInput) => {
    if (day === undefined) {
      return onChange?.(undefined, name);
    }

    return onChange?.(moment.utc(day).format(), name);
  };

  return (
    <InputField
      className={className}
      descriptionText={descriptionText}
      errors={errors}
      iconName="calendar"
      id={id || name}
      isRequired={isRequired}
      labelText={labelText}
      name={name}
    >
      {!isReadOnly && (
        <div className="relative">
          <DayPickerInput
            component={Input}
            dayPickerProps={{
              locale: 'en-gb',
              localeUtils: MomentLocaleUtils,
              showOutsideDays: true,
              ...(disableDaysAfter ||
                (disableDaysAfter && {
                  disabledDays: [
                    {
                      ...(!!disableDaysAfter && { after: disableDaysAfter }),
                      ...(!!disableDaysBefore && { before: disableDaysBefore }),
                    },
                  ],
                })),
              ...dayPickerProps,
            }}
            format={dateFormat ?? 'DD/MM/YYYY'}
            formatDate={formatDate}
            onDayChange={handleDayChange}
            parseDate={parseDate}
            placeholder={placeholder}
            value={valueDate}
            inputProps={{ name, errors, disabled: isDisabled }}
          />
        </div>
      )}

      {isReadOnly && (
        <InputReadOnly {...props} key={value} defaultValue={value && moment.utc(value).format('DD/MM/YYYY')} />
      )}
    </InputField>
  );
};

export default InputCalendar;
