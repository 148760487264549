import { Auth } from '@aws-amplify/auth';

type AuthProvider = 'al' | 'ed';

interface RequestAuth {
  token: string;
  authProvider: AuthProvider;
}

type GetRequestAuth = (authToken: string) => Promise<RequestAuth>;

export const getRequestAuth: GetRequestAuth = async (authToken) => {
  try {
    const session = await Auth.currentSession();
    return {
      // @ts-ignore
      token: session.idToken.jwtToken,
      authProvider: 'al',
    };
  } catch (err) {
    return {
      token: authToken,
      authProvider: 'ed',
    };
  }
};
