import React, { useContext } from 'react';

import type { ClauseShort, UIInputValue } from '../../../../@types/types';
import Editor from '../../../components/Editor';
import InputSelect from '../../../components/InputSelect';
import InputText from '../../../components/InputText';
import VerticalTabsContext from '../../WysiwygManager/contexts/VerticalTabsContext';
import ClauseLibraryContext from '../ClauseLibraryContext';
import { clauseTypes } from '../constants';
import { useClause } from '../useClause';

export const EditClause = () => {
  const {
    clauseDescription,
    clauseType,
    clauseBody,
    setClauseDescription,
    setClauseType,
    setClauseBody,
    setModalTitle,
    handleCloseModal,
    clauseId,
  } = useContext(ClauseLibraryContext);
  const { entityClausesState, isTemplate } = useContext(VerticalTabsContext);
  const { persistClauses } = useClause();

  const handleChangeClauseDescription = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    setClauseDescription(value);
    setModalTitle(`Edit clause - ${value}`);
  };
  const handleChangeClauseType = (type: UIInputValue) => setClauseType(type);
  const handleChangeClauseBody = (body: UIInputValue) => setClauseBody(body);

  const handleEditSubmissionClause = async () => {
    try {
      handleCloseModal();
      console.log('handleEditSubmissionClause entityClausesState before persistClauses:', entityClausesState);
      const newClauses = entityClausesState.map((clause: ClauseShort) =>
        clause.id === clauseId
          ? { ...clause, description: clauseDescription, type: clauseType, content: clauseBody, is_edited: true }
          : clause
      );
      persistClauses(
        newClauses,
        newClauses.find((i: ClauseShort) => i.id === clauseId)
      );
    } catch (e) {
      console.log(e);
    }
  };
  const handleDeleteClause = async () => {
    try {
      handleCloseModal();
      const clauseToDelete = entityClausesState.find((clause: ClauseShort) => clause.id === clauseId);
      persistClauses(
        entityClausesState.filter((clause: ClauseShort) => clause.id !== clauseId),
        { ...clauseToDelete, deleted_at: new Date().toISOString() }
      );
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <InputText
        labelText="Clause name"
        isRequired
        value={clauseDescription}
        onChange={handleChangeClauseDescription}
      />
      <InputSelect labelText="Type" value={clauseType} options={clauseTypes} onChange={handleChangeClauseType} />
      <div className="edit-submission-clause">
        <Editor name="clause" content={clauseBody} onUpdate={handleChangeClauseBody} />
      </div>
      <div className="flex flex-row">
        <div className="basis-1/2 w-1/2">
          <div className="mt-5 sm:mt-4 sm:flex sm:flex">
            <button
              onClick={handleDeleteClause}
              type="button"
              className="focus:ring-red-500 bg-red-600 hover:bg-red-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
            >
              Remove
            </button>
          </div>
        </div>
        <div className="basis-1/2 w-1/2">
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            {!isTemplate && (
              <button
                onClick={handleEditSubmissionClause}
                type="button"
                className="focus:ring-blue-500 bg-blue-600 hover:bg-blue-700 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm"
              >
                Submit
              </button>
            )}
            <button
              onClick={handleCloseModal}
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
