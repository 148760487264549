import TableCellExtenstion from '@tiptap/extension-table-cell';

export type Border = 'top' | 'right' | 'bottom' | 'left';

export const TableCell = () =>
  TableCellExtenstion.extend({
    addAttributes() {
      return {
        colspan: {
          default: 1,
        },
        rowspan: {
          default: 1,
        },
        colwidth: {
          default: null,
          parseHTML: (element: any) => {
            const colwidth = element.getAttribute('colwidth') ?? element.style.width.replace('px', '');
            return colwidth ? [parseInt(colwidth, 10)] : null;
          },
          renderHTML: ({ colwidth }: { colwidth: [number] }) => {
            if (!colwidth || !colwidth[0]) {
              return { style: `max-width: calc(640px - 220px)` };
            }
            return { style: `width: ${colwidth[0]}px` };
          },
        },
        rightalign: {
          default: null,
          parseHTML: (element: any) => element.getAttribute('rightalign'),
        },
        backgroundColor: {
          default: null,
          parseHTML: (element: any) => element.getAttribute('data-background-color'),
          renderHTML: (attributes: any) => {
            return {
              'data-background-color': attributes.backgroundColor,
              style: attributes.backgroundColor ? `background-color: ${attributes.backgroundColor}` : '',
            };
          },
        },
        hidetop: {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('hidetop') === 'true'),
        },
        hideright: {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('hideright') === 'true'),
        },
        hidebottom: {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('hidebottom') === 'true'),
        },
        hideleft: {
          default: false,
          parseHTML: (element: any) => !!(element.getAttribute('hideleft') === 'true'),
        },
      };
    },
    // @ts-ignore
    addCommands() {
      return {
        toggleBorder:
          (border: Border) =>
          ({ commands }: { commands: any }) => {
            commands.setCellAttribute(
              `hide${border}`,
              this.editor.getAttributes('tableCell')[`hide${border}`] === true ? false : true
            );
          },
        toggleAllBorders:
          () =>
          ({ commands }: { commands: any }) => {
            const attributes = this.editor.getAttributes('tableCell');
            let newValue = true;
            if (attributes.hidetop && attributes.hideright && attributes.hidebottom && attributes.hideleft) {
              newValue = false;
            }
            commands.setCellAttribute('hidetop', newValue);
            commands.setCellAttribute('hideright', newValue);
            commands.setCellAttribute('hidebottom', newValue);
            commands.setCellAttribute('hideleft', newValue);
          },
      };
    },
  });
