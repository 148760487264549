import React from 'react';

const Form: React.FC<React.FormHTMLAttributes<HTMLFormElement>> = ({ onSubmit, ...props }) => {
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (onSubmit) {
      onSubmit(event);
    }
  };

  return <form onSubmit={handleSubmit} {...props} />;
};

export default Form;
