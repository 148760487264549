import React from 'react';

import clsx from 'clsx';

const DescriptionText: React.FC<React.HTMLAttributes<HTMLParagraphElement>> = ({ children, className, ...props }) => (
  <p className={clsx('mt-2 text-sm text-gray-900', className)} {...props}>
    {children}
  </p>
);

export default DescriptionText;
