import pointer from 'json-pointer';
import traverse from 'json-schema-traverse';
import mergeWith from 'lodash.mergewith';
import unset from 'lodash.unset';

import ajv from './ajv';

const keywords = ['allOf', 'anyOf', 'oneOf', 'not', 'if', 'then', 'else'];

const customizer = (objValue: any, srcValue: any) => {
  if (Array.isArray(objValue)) {
    return srcValue.length === 0 ? [] : objValue.concat(srcValue.filter((item: any) => objValue.indexOf(item) === -1));
  }
};

const isKeyword = (item: any) => keywords.includes(item);

export const resolveRefs = (schema: any) => {
  traverse(schema, {
    cb: (currSchema) => {
      if ('$ref' in currSchema) {
        const pathToDefinition = currSchema?.['$ref']?.split('#')?.[1];
        const definition = pointer.get(schema, pathToDefinition) || {};

        mergeWith(currSchema, definition);
        unset(currSchema, '$ref');
      }
    },
  });
};

// @ts-ignore
export const resolveSchema = (parentSchema: any, formValues = {}) => {
  const hasProperties = 'properties' in parentSchema;
  const hasAllOf = 'allOf' in parentSchema;
  const hasDependencies = 'dependencies' in parentSchema;
  const dependencies = hasDependencies ? parentSchema.dependencies : {};
  const hasIf = 'if' in parentSchema;
  const hasThen = 'then' in parentSchema;
  const hasElse = 'else' in parentSchema;

  Object.keys(formValues).forEach((key) => {
    // @ts-ignore
    const fieldValue = formValues[key];
    const fieldDeps = dependencies[key];
    const fieldHasValue = fieldValue !== '' && fieldValue !== undefined;
    const fieldHasDeps = Boolean(fieldDeps);
    const fieldHasKeywordDeps = fieldHasDeps && Object.keys(fieldDeps).some(isKeyword);
    const fieldHasOneOfDeps = fieldHasKeywordDeps && 'oneOf' in fieldDeps;

    if (hasAllOf) {
      parentSchema?.allOf?.forEach((allOfItem: { if: boolean | object; then: any }) => {
        if (ajv.compile(allOfItem.if)(formValues)) {
          mergeWith(parentSchema, allOfItem.then, customizer);
        }
      });
      unset(parentSchema, 'allOf');
      return resolveSchema(parentSchema, formValues);
    }

    if (fieldHasDeps && fieldHasValue) {
      if (fieldHasOneOfDeps) {
        const extendedItem =
          fieldDeps.oneOf.find(
            (item: { properties: { [x: string]: { enum: any[] } } }) => item.properties[key].enum[0] === fieldValue
          ) || {};

        unset(extendedItem.properties, key);
        mergeWith(parentSchema, extendedItem, customizer);
      } else {
        mergeWith(parentSchema, fieldDeps, customizer);
      }

      unset(parentSchema, ['dependencies', key]);

      return resolveSchema(parentSchema, formValues);
    }
  });

  if (hasIf && hasProperties) {
    const isSubschemaValid = ajv.compile(parentSchema.if)(formValues);

    if (isSubschemaValid && hasThen) {
      mergeWith(parentSchema, parentSchema.then, customizer);
    }

    if (!isSubschemaValid && hasElse) {
      mergeWith(parentSchema, parentSchema.else, customizer);
    }

    unset(parentSchema, 'if');

    return resolveSchema(parentSchema, formValues);
  }

  if (hasProperties) {
    Object.keys(parentSchema.properties).forEach((key) => {
      // @ts-ignore
      if (typeof formValues[key] === 'object' && formValues[key] !== null) {
        // @ts-ignore
        return resolveSchema(parentSchema.properties[key], formValues[key]);
      }
    });
  }

  return parentSchema;
};

export default resolveSchema;
