import React from 'react';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faAlignLeft,
  faAlignCenter,
  faAlignRight,
  faBold,
  faItalic,
  faListOl,
  faListUl,
  faRedo,
  faStrikethrough,
  faTable,
  faUnderline,
  faUndo,
  faPaintBrush,
  faPaintRoller,
  faChevronUp,
  faChevronRight,
  faChevronDown,
  faChevronLeft,
  // faBorderAll,
  faArrowUp,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, Transition } from '@headlessui/react';
import {
  mdiAppleKeyboardCaps,
  mdiFormatClear,
  mdiFormatHeader1,
  mdiFormatHeader2,
  mdiFormatHeader3,
  mdiFormatHeader4,
  mdiFormatListBulletedType,
  mdiKeyboardTab,
  mdiKeyboardTabReverse,
  mdiMinus,
  mdiTableBorder,
  mdiTableColumnPlusAfter,
  mdiTableColumnPlusBefore,
  mdiTableColumnRemove,
  mdiTableLock,
  mdiTableRemove,
  mdiTableRowPlusAfter,
  mdiTableRowPlusBefore,
  mdiTableRowRemove,
} from '@mdi/js';
import Icon from '@mdi/react';
import clsx from 'clsx';

import Tooltip from '../../../../components/Tooltip';
import { useFormatPainter } from '../../hooks/useFormatPainter';
import { iconList } from '../Editor/extensions/OrderedList';

const BASE_CLASSNAMES =
  'bg-white text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-1 py-1 text-sm font-medium';
const ACTIVE_CLASSNAMES = 'bg-indigo-50 border-indigo-500 text-indigo-600';
const DISABLED_CLASSNAMES = 'bg-gray-300 cursor-not-allowed hover:bg-gray-300';

type IconType = string | IconProp;

const TooltipIcon: React.FC<{ icon: IconType }> = ({ icon }) => {
  return typeof icon === 'string' ? (
    <Icon path={icon} size={'18px'} className="-my-2 -mx-1" />
  ) : (
    <FontAwesomeIcon icon={icon} />
  );
};

interface RenderFormatterArgs {
  format: () => void;
  isActive: boolean;
  isDisabled: boolean;
  icon: IconType;
  key: string;
  title: string;
}
interface RenderTableControlArgs {
  isActive: boolean;
  isDisabled: boolean;
  editor: any;
}

const renderFormatter = ({ format, isActive, isDisabled = false, icon, key, title }: RenderFormatterArgs) => (
  <Tooltip content={title} key={key}>
    <button
      onClick={format}
      disabled={isDisabled}
      className={clsx(BASE_CLASSNAMES, isActive && ACTIVE_CLASSNAMES, isDisabled && DISABLED_CLASSNAMES)}
      style={{ height: '24px' }}
    >
      <TooltipIcon icon={icon} />
    </button>
  </Tooltip>
);

const renderTableControl = ({ isActive, isDisabled = false, editor }: RenderTableControlArgs) => {
  const [x, setX] = React.useState(2);
  const [y, setY] = React.useState(2);
  const [rows, setRows] = React.useState(5);
  const [columns, setColumns] = React.useState(5);
  const MAX_SIZE = 10;
  const select = (newX: number, newY: number) => {
    setX(newX);
    setY(newY);
    if (rows === newX && rows < MAX_SIZE) {
      setRows(rows + 1);
    }
    if (columns === newY && columns < MAX_SIZE) {
      setColumns(columns + 1);
    }
  };
  const onSelected = () => {
    editor.chain().focus().insertTable({ rows: y, cols: x, withHeaderRow: false }).run();
  };
  return (
    <Popover className="relative" key="Table">
      {({ open }) => {
        React.useEffect(() => {
          if (!open) {
            setTimeout(() => {
              x !== 2 && setX(2);
              y !== 2 && setY(2);
              rows !== 5 && setRows(5);
              columns !== 5 && setColumns(5);
            }, 100);
          }
        }, [open]);
        return (
          <div>
            <Tooltip content="Table" key="table">
              <Popover.Button
                disabled={isDisabled}
                className={clsx(BASE_CLASSNAMES, isActive && ACTIVE_CLASSNAMES, isDisabled && DISABLED_CLASSNAMES)}
              >
                <FontAwesomeIcon icon={faTable as any} />
              </Popover.Button>
            </Tooltip>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-50 px-4 mt-3 table-control-popover-panel">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white p-3">
                  <div className="relative flex row justify-start cursor-pointer">
                    {Array.from({ length: rows }, (_, i) => (
                      <div key={`row-${i}`}>
                        {Array.from({ length: columns }, (_, j) => (
                          <div
                            key={`column-${j}`}
                            className={clsx(
                              'h-5 w-5 m-1 my-2 border rounded-sm',
                              i < x && j < y ? 'bg-blue-100 border-blue-500' : 'bg-white border-gray-300'
                            )}
                            onMouseOver={() => select(i + 1, j + 1)}
                            onClick={onSelected}
                          ></div>
                        ))}
                      </div>
                    ))}
                  </div>
                  <div className="flex py-3">
                    <p className="m-auto font-normal text-sm">
                      {y} x {x}
                    </p>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        );
      }}
    </Popover>
  );
};
const OrderedListControl: React.FC<Omit<RenderTableControlArgs, 'isActive'>> = ({ isDisabled = false, editor }) => {
  const type = editor.getAttributes('orderedList').type;
  return (
    <Popover className="relative flex content-center" key="ordered_list_type">
      {() => {
        return (
          <div className="flex">
            <Tooltip content="Set ordered list type" key="ordered_list_type">
              <Popover.Button
                disabled={isDisabled}
                className={clsx(BASE_CLASSNAMES, isDisabled && DISABLED_CLASSNAMES)}
              >
                <Icon path={mdiFormatListBulletedType} size={'18px'} className="-my-1 -mx-1" />
              </Popover.Button>
            </Tooltip>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-50 px-4 mt-3 top-5 -left-3">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white p-3">
                  <div className="relative flex flex-col justify-start cursor-pointer items-stretch whitespace-nowrap text-left">
                    {iconList.map((attr) => {
                      const selected = type === attr;
                      const onClick = () => editor.chain().setIconType(attr).run();
                      return (
                        <button
                          key={attr}
                          onClick={onClick}
                          className={clsx(
                            `hover:bg-gray-100 text-left rounded-sm py-1 pr-1 flex content-center`,
                            selected && `bg-gray-100`
                          )}
                        >
                          <div className="font-medium w-6 text-center">{attr}.</div>
                          <span>
                            {(() => {
                              switch (attr) {
                                case 'a':
                                  return 'Letters';
                                case 'i':
                                  return 'Roman literals';
                                case '1':
                                  return 'Numbers';
                                case '1.1':
                                  return 'Decimal';
                              }
                            })()}
                          </span>
                        </button>
                      );
                    })}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        );
      }}
    </Popover>
  );
};

interface ControlsProps {
  editor: any;
  isDisabled: boolean;
  unformattedPaste: boolean;
  onUnformattedPasteClick: () => void;
  className?: string;
}

export const Controls: React.FC<ControlsProps> = ({
  editor,
  isDisabled,
  unformattedPaste,
  onUnformattedPasteClick,
  className = '',
}) => {
  const showTableControls = editor.isActive('table');
  const { updateFormat, applyFormat, applyEnabled } = useFormatPainter(editor);
  const formatters = [
    {
      format: () => editor.chain().focus().toggleBold().run(),
      isActive: editor.isActive('bold'),
      icon: faBold,
      key: 'bold',
      title: 'Bold',
    },
    {
      format: () => editor.chain().focus().toggleItalic().run(),
      isActive: editor.isActive('italic'),
      icon: faItalic,
      key: 'italic',
      title: 'Italic',
    },
    {
      format: () => editor.chain().focus().toggleUnderline().run(),
      isActive: editor.isActive('underline'),
      icon: faUnderline,
      key: 'underline',
      title: 'Underline',
    },
    {
      format: () => editor.chain().focus().toggleStrike().run(),
      isActive: editor.isActive('strike'),
      icon: faStrikethrough,
      key: 'strike',
      title: 'Strike-through',
    },
    {
      format: () => editor.chain().focus().toggleUppercase().run(),
      isActive: editor.isActive('uppercase'),
      icon: mdiAppleKeyboardCaps,
      key: 'uppercase',
      title: 'Uppercase',
    },
    {
      format: onUnformattedPasteClick,
      isActive: unformattedPaste,
      icon: mdiFormatClear,
      key: 'unformatted-paste',
      title: 'Unformatted text',
    },
    {
      format: () => editor.chain().focus().unsetAllMarks().toggleHeading({ level: 1 }).run(),
      isActive: editor.isActive('heading', { level: 1 }),
      icon: mdiFormatHeader1,
      key: 'heading-1',
      title: 'Heading Large Red',
    },
    {
      format: () => editor.chain().focus().unsetAllMarks().toggleHeading({ level: 2 }).run(),
      isActive: editor.isActive('heading', { level: 2 }),
      icon: mdiFormatHeader2,
      key: 'heading-2',
      title: 'Heading Large Black',
    },
    {
      format: () => editor.chain().focus().unsetAllMarks().toggleHeading({ level: 3 }).run(),
      isActive: editor.isActive('heading', { level: 3 }),
      icon: mdiFormatHeader3,
      key: 'heading-3',
      title: 'Heading Medium Black',
    },
    {
      format: () => editor.chain().focus().unsetAllMarks().toggleHeading({ level: 4 }).run(),
      isActive: editor.isActive('heading', { level: 4 }),
      icon: mdiFormatHeader4,
      key: 'heading-4',
      title: 'Heading Large Underline',
    },
    {
      format: updateFormat,
      isActive: false,
      icon: faPaintRoller,
      key: 'format-copy',
      title: 'Copy Format',
    },
    {
      format: applyFormat,
      isActive: applyEnabled,
      icon: faPaintBrush,
      key: 'format-apply',
      title: 'Apply format',
    },
    {
      custom: () => <Separator key="1" />,
    },
    {
      format: () => editor.chain().focus().toggleBulletList().run(),
      isActive: editor.isActive('bulletList'),
      icon: faListUl,
      key: 'bulletList',
      title: 'Bulleted list',
    },
    {
      format: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: editor.isActive('orderedList'),
      icon: faListOl,
      key: 'orderedList',
      title: 'Ordered list',
    },
    {
      custom: () => (
        <OrderedListControl
          isDisabled={isDisabled || !editor.isActive('orderedList')}
          editor={editor}
          key="ordered_list"
        />
      ),
    },
    {
      format: () =>
        editor
          .chain()
          .focus()
          .updateAttributes('orderedList', { start: (editor.getAttributes('orderedList').start || 1) + 1 })
          .run(),
      isDisabled: isDisabled || !editor.isActive('orderedList'),
      icon: faArrowUp,
      key: 'increaseStart',
      title: 'Increase Start',
    },
    {
      format: () =>
        editor
          .chain()
          .focus()
          .updateAttributes('orderedList', { start: (editor.getAttributes('orderedList').start || 1) - 1 })
          .run(),
      isDisabled: isDisabled || !editor.isActive('orderedList'),
      icon: faArrowDown,
      key: 'decreaseStart',
      title: 'Decrease Start',
    },
    {
      format: () => editor.chain().focus().indentRight().run(),
      isActive: false,
      icon: mdiKeyboardTab,
      key: 'indentRight',
      title: 'Indent right',
    },
    {
      format: () => editor.chain().focus().indentLeft().run(),
      isActive: false,
      icon: mdiKeyboardTabReverse,
      key: 'indentLeft',
      title: 'Indent left',
    },
    {
      custom: () => <Separator key="2" />,
    },
    {
      format: () => editor.chain().focus().setTextAlign('left').run(),
      isActive: editor.isActive({ textAlign: 'left' }),
      icon: faAlignLeft,
      key: 'alignLeft',
      title: 'Left align',
    },
    {
      format: () => editor.chain().focus().setTextAlign('center').run(),
      isActive: editor.isActive({ textAlign: 'center' }),
      icon: faAlignCenter,
      key: 'alignCenter',
      title: 'Centre align',
    },
    {
      format: () => editor.chain().focus().setTextAlign('right').run(),
      isActive: editor.isActive({ textAlign: 'right' }),
      icon: faAlignRight,
      key: 'alignRight',
      title: 'Right align',
    },
    {
      custom: () => <Separator key="3" />,
    },
    showTableControls && {
      format: () => editor.chain().focus().toggleBorder('top').run(),
      isActive: editor.isActive({ hidetop: true }),
      icon: faChevronUp,
      key: 'hide-top-border',
      title: 'Toggle Top Border',
    },
    showTableControls && {
      format: () => editor.chain().focus().toggleBorder('right').run(),
      isActive: editor.isActive({ hideright: true }),
      icon: faChevronRight,
      key: 'hide-right-border',
      title: 'Toggle Right Border',
    },
    showTableControls && {
      format: () => editor.chain().focus().toggleBorder('bottom').run(),
      isActive: editor.isActive({ hidebottom: true }),
      icon: faChevronDown,
      key: 'hide-bottom-border',
      title: 'Toggle Bottom Border',
    },
    showTableControls && {
      format: () => editor.chain().focus().toggleBorder('left').run(),
      isActive: editor.isActive({ hideleft: true }),
      icon: faChevronLeft,
      key: 'hide-left-border',
      title: 'Toggle Left Border',
    },
    // This is behaving strangely. Instead of all borders, it first toggles right and left on first click,
    // toggles bottom on second click and finally toggles top on third click. We should check in detail later
    /* showTableControls && {
      format: () => editor.chain().focus().toggleAllBorders().run(),
      isActive: editor.isActive({ hidetop: true, hideright: true, hidebottom: true, hideleft: true }),
      icon: faBorderAll,
      key: 'hide-all-border',
      title: 'Toggle All Borders',
    }, */
    showTableControls && {
      custom: () => <Separator key="4" />,
    },
    {
      format: () => editor.chain().focus().setHorizontalRule().run(),
      isActive: false,
      icon: mdiMinus,
      key: 'horizontalRule',
      title: 'Insert page break',
    },
    {
      custom: () => <Separator key="6" />,
    },
    {
      format: () => editor.chain().focus().undo().run(),
      isActive: false,
      icon: faUndo,
      key: 'undo',
      title: 'Undo',
    },
    {
      format: () => editor.chain().focus().redo().run(),
      isActive: false,
      icon: faRedo,
      key: 'redo',
      title: 'Redo',
    },
    {
      custom: () => <Separator key="7" />,
    },
    {
      custom: () =>
        renderTableControl({
          editor,
          isActive: editor.isActive('table'),
          isDisabled,
        }),
    },
    showTableControls && {
      format: () => editor.chain().focus().toggleTableBold().run(),
      isActive: editor.isActive('table', { bold: true }),
      isDisabled: !editor.isActive('table'),
      icon: mdiTableBorder,
      key: 'toggleTableBorder',
      title: 'Table border visibility',
    },
    showTableControls && {
      format: () => editor.chain().focus().toggleLock().run(),
      isActive: false,
      isDisabled: !editor.isActive('table'),
      icon: mdiTableLock,
      key: 'lockTable',
      title: 'Toggle first column lock',
    },
    showTableControls && {
      format: () => editor.chain().focus().deleteTable().run(),
      isActive: false,
      isDisabled: !editor.isActive('table'),
      icon: mdiTableRemove,
      key: 'removeTable',
      title: 'Remove Table',
    },
    showTableControls && {
      custom: () => <Separator key="8" />,
    },
    showTableControls && {
      format: () => editor.chain().focus().addColumnAfter().run(),
      isActive: false,
      isDisabled: !editor.isActive('table'),
      icon: mdiTableColumnPlusAfter,
      key: 'addColumnAfter',
      title: 'Add column after',
    },
    showTableControls && {
      format: () => editor.chain().focus().addColumnBefore().run(),
      isActive: false,
      isDisabled: !editor.isActive('table'),
      icon: mdiTableColumnPlusBefore,
      key: 'addColumnBefore',
      title: 'Add column before',
    },
    showTableControls && {
      format: () => editor.chain().focus().deleteColumn().run(),
      isActive: false,
      isDisabled: !editor.isActive('table'),
      icon: mdiTableColumnRemove,
      key: 'removeColumn',
      title: 'Remove selected column',
    },
    showTableControls && {
      custom: () => <Separator key="9" />,
    },
    showTableControls && {
      format: () => editor.chain().focus().addRowAfter().run(),
      isActive: false,
      isDisabled: !editor.isActive('table'),
      icon: mdiTableRowPlusAfter,
      key: 'addRowAfter',
      title: 'Add row after',
    },
    showTableControls && {
      format: () => editor.chain().focus().addRowBefore().run(),
      isActive: false,
      isDisabled: !editor.isActive('table'),
      icon: mdiTableRowPlusBefore,
      key: 'addRowBefore',
      title: 'Add row before',
    },
    showTableControls && {
      format: () => editor.chain().focus().deleteRow().run(),
      isActive: false,
      isDisabled: !editor.isActive('table'),
      icon: mdiTableRowRemove,
      key: 'removeRow',
      title: 'Remove selected row',
    },
  ];

  return (
    <div
      className={clsx(
        `border-gray-200 overflow-visible border-b flex space-x-4 p-2 bg-white z-10 flex-wrap ${
          showTableControls ? 'w-auto' : 'w-full'
        }`,
        className
      )}
    >
      {formatters.map((item) => {
        if (!item) return;
        if (item.custom !== undefined) {
          return item.custom();
        }
        if (isDisabled) item.isDisabled = isDisabled;

        return renderFormatter(item);
      })}
    </div>
  );
};

const Separator = () => {
  return <div className="border border-grey-400 -mx-2"></div>;
};
