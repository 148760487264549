import type { ClauseLibrary } from '../../@types/types';

export const getClausesEditorContent = (clauses: ClauseLibrary = []): string => {
  const clausesLength = clauses.length;
  if (!clausesLength) {
    return '<p></p>';
  }

  let editorContent = '';
  for (let i = 0; i < clausesLength; i++) {
    const { content } = clauses[i];
    editorContent += content;
    if (i < clausesLength - 1) {
      editorContent += '<hr>';
    }
  }

  return editorContent;
};
