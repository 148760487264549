import React from 'react';

import clsx from 'clsx';

import type { UIComponentBase } from '../../@types/types';

interface DivWithTag extends React.HTMLAttributes<HTMLDivElement> {
  tag?: React.ReactNode;
}

interface HeaderProps extends DivWithTag {
  headingText?: string;
  descriptionText?: React.ReactNode;
}

interface CardProps extends HeaderProps {
  content?: string;
  header?: React.ReactNode;
}

type ContentComponent = React.FC<UIComponentBase>;
type DescriptionComponent = React.FC<UIComponentBase>;
type HeaderComponent = React.FC<HeaderProps>;
type HeadingComponent = React.FC<DivWithTag>;

type CardComponent = React.FC<CardProps> & {
  Content: ContentComponent;
  Description: DescriptionComponent;
  Header: HeaderComponent;
  Heading: HeadingComponent;
};

const Heading: HeadingComponent = ({ className, children, tag, ...props }) => (
  <div className="flex items-center" {...props}>
    <h3 className={clsx('text-xl leading-relaxed font-semibold mr-4', className)}>{children}</h3>
    {tag}
  </div>
);

const Description: DescriptionComponent = ({ className, children }) => (
  <div className={clsx('text-sm leading-snug mt-2', className)}>{children}</div>
);

const Header: HeaderComponent = ({ className, headingText, descriptionText, children, tag, onClick }) => (
  <div className={clsx('', !clsx(className).match(/(p[xy]?)-(\d+)/) && 'p-4', className)} onClick={onClick}>
    {headingText && <Heading tag={tag}>{headingText}</Heading>}

    {descriptionText && <Description>{descriptionText}</Description>}

    {children}
  </div>
);

const Content: ContentComponent = ({ className, children }) => (
  <div className={clsx('pt-4 px-4', className)}>{children}</div>
);

const Card: CardComponent = ({ children, className, content, descriptionText, header, headingText, tag, ...props }) => (
  <div className={clsx('', className)} {...props}>
    {header}

    {!header && (headingText || descriptionText) && (
      <Header headingText={headingText} descriptionText={descriptionText} tag={tag} />
    )}

    {content && <Content>{content}</Content>}

    {children}
  </div>
);

Card.Content = Content;
Card.Description = Description;
Card.Header = Header;
Card.Heading = Heading;

export default Card;
