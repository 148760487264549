import React, { useState } from 'react';

import cloneDeep from 'lodash.clonedeep';
import set from 'lodash.set';

import type { GenericObject, UIChildrenMappingProps, UIMapChildrenFn } from '../../../@types/types';
import pickCommaSeparate from '../../helpers/pickCommaSeparate';
import Button from '../Button';
import InputAddress from '../InputAddress';

interface AddressAutocompleteProps extends UIChildrenMappingProps {
  id: string;
  item: any;
  mapChildren: UIMapChildrenFn;
  ownKey: string;
  parentKey: string;
  className: string;
  value: GenericObject<string>;
  errors: string | string[];
  isReadOnly: boolean;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  formValues,
  id: _id,
  item,
  parentKey: _parentKey,
  ownKey,
  className,
  value,
  errors,
  isReadOnly,
  validationErrors,
  onChange,
  mapChildren,
  setFormValues,
  ...props
}) => {
  const hasInitialValue = value && Object.keys(value).length > 0;
  const [isAddressFormVisible, setIsAddressFormVisible] = useState(hasInitialValue);

  const initialValue = pickCommaSeparate(value, [
    'address_line_1',
    'address_line_2',
    'city',
    'state',
    'zipcode',
    'country',
  ]);
  const handlePlaceChange = (newPlace: GenericObject) => {
    const clonedFormValues = cloneDeep(formValues);

    set(clonedFormValues, ownKey, newPlace);
    setFormValues(clonedFormValues);
    setIsAddressFormVisible(true);
  };

  return (
    <div className={className}>
      <InputAddress
        className="mb-8"
        labelText="Search for address"
        name="addressSearch"
        onPlaceChange={handlePlaceChange}
        errors={errors}
        {...props}
        defaultValue={initialValue}
        allowedCountries={item['ui:component:allowedCountries']}
        isReadOnly={isReadOnly}
      />

      {!isAddressFormVisible && !isReadOnly && (
        <Button data-testid="input-address-button" kind="primary" onClick={() => setIsAddressFormVisible(true)}>
          Input manually
        </Button>
      )}

      {isAddressFormVisible && (
        <div className="pl-8 border-l-4 border-gray-400">
          {mapChildren({
            parentSchema: item,
            formValues,
            parentKey: ownKey,
            onChange,
            setFormValues,
            validationErrors,
            isReadOnly,
          })}
        </div>
      )}
    </div>
  );
};

export default AddressAutocomplete;
