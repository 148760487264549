import { Fragment, useContext } from 'react';

import { PencilIcon } from '@heroicons/react/outline';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import type { ClauseShort } from '../../../../@types/types';
import Tooltip from '../../../components/Tooltip';
import VerticalTabsContext from '../../WysiwygManager/contexts/VerticalTabsContext';
import ClauseLibraryContext from '../ClauseLibraryContext';
import { useClause } from '../useClause';

export const EntityClauses = () => {
  const {
    setModalTitle,
    setIsModalOpen,
    setIsEditClause,
    setClauseId,
    setClauseDescription,
    setClauseType,
    setClauseBody,
  } = useContext(ClauseLibraryContext);
  const { entityClausesState } = useContext(VerticalTabsContext);
  const { persistClauses, isClausePersistInProgress } = useClause();
  const handleSetEditSubmissionClauseModalOpen =
    ({ id, description, type, content }: ClauseShort) =>
    () => {
      setModalTitle(`Edit clause - ${description}`);
      setIsModalOpen(true);
      setIsEditClause(true);
      setClauseId(id);
      setClauseDescription(description);
      setClauseType(type);
      setClauseBody(content);
    };

  if (!entityClausesState.length) {
    return null;
  }

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  async function onDragEnd(result: any) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedEntityClauses = reorder(entityClausesState, result.source.index, result.destination.index);
    persistClauses(reorderedEntityClauses, null, {
      sourceIndex: result.source.index,
      targetIndex: result.destination.index,
    });
  }
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {entityClausesState.map((currentClause: ClauseShort, index: number) => (
              <Fragment key={currentClause.id}>
                <Draggable
                  key={currentClause.id}
                  draggableId={currentClause.id}
                  index={index}
                  isDragDisabled={isClausePersistInProgress}
                >
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                      <ul className="relative pl-4 pr-2 py-2">
                        <li key={currentClause.id}>
                          <div className="flex flex-row">
                            <DotsVerticalIcon className="block h-4 w-4 mr-2" aria-hidden="true" />
                            <Tooltip
                              content={`${currentClause.description}${currentClause.is_edited ? ' [Amended]' : ''}`}
                            >
                              <div
                                className={`w-5/6 text-sm truncate ${isClausePersistInProgress ? 'opacity-50' : ''}`}
                              >{`${currentClause.description} ${currentClause.is_edited ? ' [Amended]' : ''} `}</div>
                            </Tooltip>
                            <div className="w-1/6 text-right">
                              <button
                                className="disabled:opacity-50 disabled:cursor-not-allowed"
                                disabled={isClausePersistInProgress}
                              >
                                <PencilIcon
                                  className="block h-4 w-4 mr-2"
                                  onClick={handleSetEditSubmissionClauseModalOpen(currentClause)}
                                />
                              </button>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </Draggable>
              </Fragment>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
