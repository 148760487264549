import axios from 'axios';

import type { Clause, ClausePayload } from '../../@types/types';
import { getRequestAuth } from '../helpers/getRequestAuth';

export const createClauseInLibrary = async (data: ClausePayload, authToken: string): Promise<Pick<Clause, 'id'>> => {
  const auth = await getRequestAuth(authToken);

  const response = await axios({
    method: 'post',
    url: `${process.env.REACT_APP_API_URL}/clauses${auth.authProvider === 'al' ? '-spa-al' : ''}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: auth.token,
    },
    data,
  });

  return response.data;
};
