import React from 'react';

import './index.css';
import type { Editor } from '@tiptap/react';
import { sanitize } from 'dompurify';

const customAttributes = [
  'bold',
  'locked',
  'monofont',
  'hidetop',
  'hideleft',
  'hidebottom',
  'hideright',
  'indent',
  'pre-set-type',
  'colwidth',
  'rightalign',
  'backgroundColor',
];

interface PreviewProps {
  editor: Editor;
  documentType: string;
}

export const Preview: React.FC<PreviewProps> = ({ editor, documentType }) => {
  return (
    <div
      className={`wysiwyg ${documentType.toLowerCase()} prose prose-sm sm:prose lg:prose-lg m-5 focus:outline-none mention-cleanup`}
      dangerouslySetInnerHTML={{
        __html: sanitize((editor as any).getHTML(), { ADD_ATTR: customAttributes }),
      }}
    />
  );
};
