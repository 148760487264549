// @ts-ignore
import { Mark } from '@tiptap/core';

export const Subscript = Mark.create({
  name: 'subscript',

  parseHTML() {
    return [
      {
        tag: 'sub',
      },
      {
        style: 'vertical-align',
        getAttrs: (value: string) => value === 'sub' && null,
      },
    ];
  },

  renderHTML() {
    return ['sub', 0];
  },

  addCommands() {
    return {
      toggleSubscript:
        () =>
        ({ commands }: any) => {
          return commands.toggleMark(this.name);
        },
    };
  },
});
