import React from 'react';

import type { InputMultiSelectProps } from '../InputMultiSelect';
import InputMultiSelect from '../InputMultiSelect';

interface InputMultiSelectSchemaProps extends InputMultiSelectProps {
  item: { enum: string[] };
}

const InputMultiSelectSchema: React.FC<InputMultiSelectSchemaProps> = ({ item, ...props }) => {
  return <InputMultiSelect {...props} options={item.enum} />;
};

export default InputMultiSelectSchema;
