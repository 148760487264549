import React from 'react';

import sortBy from 'lodash.sortby';

import type {
  HumanReadableError,
  UIChildrenMappingProps,
  UIMapChildrenFn,
  UIMapChildrenFnArgs,
} from '../../../@types/types';
import formatErrors from '../../formatErrors';

type Item = any;

interface MakeComponentProps extends Omit<UIMapChildrenFnArgs, 'validationErrors' | 'showQuestionKey'> {
  item: Item;
  noErrorLabel: boolean;
  validationErrors: HumanReadableError[] | null;
  mapChildren: UIMapChildrenFn;
}

interface InputMatrixProps extends UIChildrenMappingProps {
  item: Item;
  ownKey: string;
  mapChildren: UIMapChildrenFn;
  MakeComponent: React.FC<MakeComponentProps>;
}

const InputMatrix: React.FC<InputMatrixProps> = ({
  formValues,
  item,
  ownKey,
  validationErrors,
  MakeComponent,
  onChange,
  mapChildren,
  setFormValues,
}) => {
  const sortedRows = sortBy(Object.keys(item.properties), (key) => item.properties[key].qid);

  return (
    <div>
      {sortedRows.map((rowKey) => {
        const rows = item.properties[rowKey].properties;
        const sortedColumns = sortBy(Object.keys(rows), (columnKey) => rows[columnKey].qid);

        return (
          <div key={rowKey}>
            <p className="font-semibold mb-4">{item.properties[rowKey].title}</p>
            <div className="flex -mx-2 justify-between items-end">
              {sortedColumns.map((columnKey) => {
                const inputSchema = {
                  ...item.properties[rowKey].properties[columnKey],
                  key: columnKey,
                };

                const inputKey = [ownKey, rowKey].join('.');

                return (
                  <div className="mx-2 flex-1" key={`${rowKey}${columnKey}`}>
                    <MakeComponent
                      item={inputSchema}
                      parentKey={inputKey}
                      formValues={formValues}
                      parentSchema={item.properties[rowKey]}
                      mapChildren={mapChildren}
                      onChange={onChange}
                      setFormValues={setFormValues}
                      validationErrors={formatErrors(validationErrors)}
                      noErrorLabel
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InputMatrix;
