import type { Node } from 'prosemirror-model';
import type { Selection } from 'prosemirror-state';

// assuming that there is a given node above
export const findFirstNodeAbove = (
  nodeName: string,
  { $from }: Selection<any>
): { node: Node<any>; before: number } => {
  for (let depth = $from.depth; depth >= 0; depth--) {
    let node = $from.node(depth);
    if (node.type.name === nodeName) {
      return { node, before: $from.before(depth) };
    }
  }
  throw Error('should not happen');
};
