import React from 'react';

import clsx from 'clsx';

import type { UIComponentBase } from '../../@types/types';

const H2: React.FC<UIComponentBase> = ({ className, children }) => (
  <h2 className={clsx('text-2xl leading-9 font-semibold', className)}>{children}</h2>
);

export default H2;
